import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import startCase from "lodash/startCase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DocumentViewerModal from "shared_frontend/src/DocumentViewerModal";
import ActiveProductSelector from "../../../components/ActiveProductSelector";
import AppHeader from "../../../components/AppHeader";
import { useGetEntryGenRequestsQuery } from "../../../features/API/entryGen";
import { EntryGenRequestData } from "../../../features/Copilot/EntryGen/generatedTypes";
import { useDownloadFile } from "../../../utils/helper";
import { useActiveProduct } from "../../../utils/hooks";
import { downloadEntryGenDocument, previewEntryGenDocument } from "../utils";
import styles from "./AllEntryGenRequests.module.css";
import NewEntryGenRequestModal from "./NewEntryGenRequestModal";

const GENERATION_REQUESTS_PAGE_SIZE = 20;

const AllGenerationsRequests = () => {
  const navigate = useNavigate();
  const [isNewGenerationModalOpen, setIsNewGenerationModalOpen] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const activeProduct = useActiveProduct();
  const [selectedDocument, setSelectedDocument] =
    useState<EntryGenRequestData | null>(null);
  const [previewBlob, setPreviewBlob] = useState<Blob>();
  const [previewError, setPreviewError] = useState(false);
  const { getFileContent } = useDownloadFile();
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);

  const { data: generations, isLoading } = useGetEntryGenRequestsQuery(
    activeProduct
      ? {
          productId: activeProduct.id,
          limit: GENERATION_REQUESTS_PAGE_SIZE,
          offset: GENERATION_REQUESTS_PAGE_SIZE * (pageNumber - 1),
        }
      : skipToken,
  );

  const handleDocumentClick = async (
    e: React.MouseEvent | React.KeyboardEvent,
    record: EntryGenRequestData,
  ) => {
    e.stopPropagation(); // Prevent row click
    setSelectedDocument(record);
    setShowDocumentPreview(true);
    setPreviewError(false);
    setPreviewBlob(undefined);

    const blob = await previewEntryGenDocument(record.id, getFileContent);
    if (blob) {
      setPreviewBlob(blob);
    } else {
      setPreviewError(true);
    }
  };

  const handleDownload = async () => {
    if (!selectedDocument) return;
    await downloadEntryGenDocument(
      selectedDocument.id,
      getFileContent,
      `${selectedDocument.documentType}-${selectedDocument.id}.pdf`,
    );
  };

  const handleRowInteraction = (record: EntryGenRequestData) => {
    navigate(`answers/${record.id}`);
  };

  const columns = [
    {
      title: "Document Type",
      key: "documentType",
      dataIndex: ["documentType"],
      render: (text: string, record: EntryGenRequestData) => {
        return (
          <span
            onClick={(e) => handleDocumentClick(e, record)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleDocumentClick(e, record);
              }
            }}
            role="button"
            tabIndex={0}
            className={styles.DocumentLink}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: ["status"],
      render: (text: string) => startCase(text),
    },
    {
      title: "Requested",
      key: "createdAt",
      dataIndex: ["createdAt"],
      render: (value: string) => dayjs(value).fromNow(),
    },
    {
      title: "Total Questions",
      key: "total",
      dataIndex: ["totalQuestions"],
    },
    { title: "Processed", key: "processed", dataIndex: ["questionsProcessed"] },
    {
      title: "Entries Generated",
      key: "questions_answered",
      dataIndex: ["questionsAnswered"],
    },
  ];

  const onNewGenerationSuccess = () => {
    setIsNewGenerationModalOpen(false);
  };

  return (
    <>
      <div className={styles.HeaderRow}>
        <AppHeader title="Generate Answer Library Entries from Documents" />
        <Button
          className={styles.ButtonUpload}
          type="primary"
          onClick={() => setIsNewGenerationModalOpen(true)}
        >
          Upload Document
        </Button>
      </div>
      <ActiveProductSelector />
      <Table
        columns={columns}
        dataSource={generations?.data}
        loading={isLoading}
        pagination={{
          current: pageNumber,
          pageSize: GENERATION_REQUESTS_PAGE_SIZE,
          total: generations?.meta?.totalCount || 0,
        }}
        onChange={(pagination) => {
          if (pagination.current) {
            setPageNumber(pagination.current);
          }
        }}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => handleRowInteraction(record),
          onKeyDown: (e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleRowInteraction(record);
            }
          },
          role: "button",
          tabIndex: 0,
          className: styles.ClickableRow,
        })}
      />
      <NewEntryGenRequestModal
        open={isNewGenerationModalOpen}
        onCancel={() => setIsNewGenerationModalOpen(false)}
        onSuccess={onNewGenerationSuccess}
        initialProductId={activeProduct?.id}
      />
      {selectedDocument && showDocumentPreview && (
        <DocumentViewerModal
          title={selectedDocument.documentType}
          onCancel={() => {
            setShowDocumentPreview(false);
            setSelectedDocument(null);
            setPreviewBlob(undefined);
            setPreviewError(false);
          }}
          onDownload={handleDownload}
          previewBlob={previewBlob}
          previewError={previewError}
        />
      )}
    </>
  );
};

export default AllGenerationsRequests;
