import { Column } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useGetConciergeKLUpdateAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { filterData, getAnalyticsMessageComponent } from "../utils";
import { ConceirgeChartProps } from "./ConciergeContainer";

/**
 * Component that displays metrics for the KL updates as a column chart
 * @param {KLUpdatesMetricsProps} props - Component props
 */
const KLUpdatesColumnChart = ({
  productId,
  activeFilter,
  theme,
  height,
  width,
}: ConceirgeChartProps): JSX.Element => {
  const {
    data: klUpdateStatsData,
    isLoading: statsLoading,
    isError: statsError,
  } = useGetConciergeKLUpdateAnalyticsQuery({
    productId,
  });

  const filteredData = useMemo(() => {
    if (!klUpdateStatsData?.data?.klUpdateStats) return [];

    return filterData(klUpdateStatsData?.data?.klUpdateStats, activeFilter);
  }, [klUpdateStatsData, activeFilter]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load KL Update Statistics",
      subTitle: "An unexpected error occurred while fetching KL updates data",
    });
  }

  if (
    !klUpdateStatsData ||
    !klUpdateStatsData?.data?.klUpdateStats ||
    klUpdateStatsData?.data?.klUpdateStats.every((stat) => stat.klUpdates === 0)
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No KL update Data",
      subTitle: "There are no KL update statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "klUpdates",
    padding: "auto",
    height,
    width,
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      items: [{ name: "KL Updates", channel: "y" }],
    },
    axis: {
      y: { title: "Number of Updates" },
      x: { title: "Month" },
    },
  };

  return <Column {...chartConfig} />;
};

export default KLUpdatesColumnChart;
