import { Column } from "@ant-design/plots";
import { Result, Skeleton } from "antd";
import { useGetAssuranceProfilePageVisitAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { filterData } from "../utils";
import { AssuranceProfileChartProps } from "./AssuranceProfileAnalyticsContainer";

const PageVisitsChart = ({
  selectedProfileIds,
  activeFilter,
  theme,
  height,
  width,
}: AssuranceProfileChartProps): JSX.Element => {
  const {
    data: pageVisitStats,
    isLoading: isPageVisitStatsLoading,
    error: isPageVisitStatsError,
  } = useGetAssuranceProfilePageVisitAnalyticsQuery({
    profileIds: selectedProfileIds,
  });

  if (isPageVisitStatsLoading) {
    return <Skeleton active />;
  }

  if (isPageVisitStatsError) {
    return (
      <Result
        status="error"
        title="Failed to Load Page Visit Statistics"
        subTitle="An unexpected error occurred while fetching page visit data"
      />
    );
  }
  if (
    !pageVisitStats ||
    !pageVisitStats.data ||
    pageVisitStats.data.every((stat) => stat.visits === 0)
  ) {
    return (
      <Result
        status="info"
        title="No Page Visit Data"
        subTitle="There are no page visit statistics available for the selected profiles"
      />
    );
  }

  const config = {
    theme,
    data: filterData(pageVisitStats.data, activeFilter),
    xField: "date",
    yField: "visits",
    height,
    width,
    axis: {
      y: { title: "Page Visits" },
      x: { title: "Month" },
    },
  };

  return <Column {...config} />;
};

export default PageVisitsChart;
