import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import {
  AppProps,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  TileItemResponse,
  UpdateResponse,
} from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import { ISODateStr } from "../types/assuranceProfile";
import { publishedDateString } from "../utils";
import useWindowDimensions from "../utils/hooks/useWindowDimensions";
import styles from "./AssuranceProfileSearch.module.scss";
import SearchResult from "./SearchResult";
import SelectedSearchItem from "./SelectedSearchItem";

interface SearchProps extends AppProps {
  tileItems: TileItemResponse[];
  updates: UpdateResponse[];
  isDarkModeOn: boolean;
}

const { Title, Paragraph } = Typography;

export type SearchableItem = TileItemResponse | UpdateResponse;

const AssuranceProfileSearch = ({
  tileItems,
  updates,
  isDarkModeOn,
}: SearchProps): JSX.Element => {
  const inputRef = useRef<InputRef>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useWindowDimensions().width < 768;

  const allResults = useMemo(
    () => [...tileItems, ...updates],
    [tileItems, updates],
  );
  const [results, setResults] = useState(allResults);
  const [selectedItem, setSelectedItem] = useState<SearchableItem | undefined>(
    results[0],
  );

  const isTileItemMatch = (item: TileItemResponse, query: string): boolean => {
    const { attributes } = item;
    return (
      attributes.title?.toLowerCase()?.includes?.(query) ||
      attributes.content?.toLowerCase()?.includes?.(query)
    );
  };

  const isProfileUpdateMatch = (
    item: UpdateResponse,
    query: string,
  ): boolean => {
    const { attributes } = item;
    return (
      attributes.header?.toLowerCase()?.includes?.(query) ||
      attributes.body?.toLowerCase()?.includes?.(query)
    );
  };

  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const filterItems = (item: SearchableItem) => {
      if (item.type === "customerAssuranceTileItem") {
        return isTileItemMatch(item, query);
      }

      return isProfileUpdateMatch(item, query);
    };
    const searchResults = allResults.filter(filterItems);

    setResults(searchResults);
  }, [searchQuery, allResults]);

  useEffect(() => {
    setSelectedItem(results[0]);
  }, [results]);

  const renderResults = () => {
    if (results.length === 0) {
      return <Paragraph strong>Sorry, no results</Paragraph>;
    }

    return results.map((item) => (
      <Card
        onClick={() => setSelectedItem(item)}
        className={clsx(styles.TileCard, styles.TileCardBody, {
          [styles.SelectedTileCard]: selectedItem?.id === item?.id,
        })}
        key={item?.id}
        hoverable
      >
        <SearchResult
          item={item}
          searchQuery={searchQuery}
          isDarkModeOn={isDarkModeOn}
        />
      </Card>
    ));
  };

  const selectedItemContent = (
    <>
      {selectedItem?.type === "customerAssuranceUpdate" && (
        <SelectedSearchItem
          source={selectedItem.attributes.header}
          publishedAt={publishedDateString(
            selectedItem.attributes.publishedAt as ISODateStr,
          )}
          item={selectedItem}
          searchQuery={searchQuery}
          isDarkModeOn={isDarkModeOn}
        />
      )}
      {selectedItem?.type === "customerAssuranceTileItem" && (
        <SelectedSearchItem
          source={selectedItem.attributes.title}
          status={selectedItem.attributes.status}
          details={selectedItem.attributes.textValue}
          item={selectedItem}
          searchQuery={searchQuery}
          isDarkModeOn={isDarkModeOn}
        />
      )}
    </>
  );

  return (
    <>
      <Button
        id={styles.SearchButton}
        icon={<SearchOutlined />}
        onClick={() => setIsModalOpen(true)}
        className={styles.SearchButton}
      >
        Keyword search
      </Button>
      <Modal
        open={isModalOpen}
        width={"90vw"}
        centered
        title={
          <>
            <Title level={3}>Assurance Profile Search</Title>
            <Divider className={styles.TitleDivider} />
          </>
        }
        footer={null}
        destroyOnClose
        onCancel={() => {
          setSearchQuery("");
          setIsModalOpen(false);
        }}
        afterOpenChange={() => inputRef.current?.focus()}
      >
        <Input
          ref={inputRef}
          size="large"
          placeholder="Keyword search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            searchQuery && <CloseOutlined onClick={() => setSearchQuery("")} />
          }
        />

        <Row gutter={16} className={styles.ContentWrapper}>
          <Col xs={24} sm={24} md={12} className={styles.SearchPane}>
            {isMobile ? searchQuery && renderResults() : renderResults()}
          </Col>

          <Col xs={24} md={0}>
            <Divider className={styles.ContentDivider} />
          </Col>

          <Col xs={24} sm={24} md={12} className={styles.SearchPane}>
            {!selectedItem?.id && (
              <Paragraph strong>No results available to select</Paragraph>
            )}

            {isMobile
              ? searchQuery && selectedItemContent
              : selectedItemContent}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AssuranceProfileSearch;
