import { Typography } from "antd";
import SanitizedHTML from "shared_utils/src/SanitizedHTML";
import { TileResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import CardHeader from "../CardHeader";
import styles from "./SeeMoreModal.module.scss";

interface TileItemContent {
  tile: TileResponse;
}

const TileItemContent = ({ tile }: TileItemContent): JSX.Element => {
  return (
    <div>
      {tile.attributes.header && (
        <CardHeader titleLevel={2} title={tile.attributes.header} />
      )}
      {tile.relationships.tileItems.map((tileItem) => (
        <div className={styles.TileItemContent} key={tileItem.id}>
          <Typography.Paragraph strong>
            {tileItem.attributes.title}
          </Typography.Paragraph>
          <SanitizedHTML html={tileItem.attributes.content} />
        </div>
      ))}
    </div>
  );
};

export default TileItemContent;
