import { Button, Modal } from "antd";
import clsx from "clsx";
import DocumentViewerContent, {
  DocumentViewerProps,
} from "../DocumentViewerContent";
import styles from "./DocumentViewerModal.module.scss";
interface DocumentViewerModalProps extends DocumentViewerProps {
  title: string;
  onCancel: () => void;
  onDownload: () => Promise<void>;
  initialPage?: number;
}
const DocumentViewerModal = ({
  previewBlob,
  previewError,
  title,
  onCancel,
  onDownload,
  initialPage,
}: DocumentViewerModalProps): JSX.Element => {
  return (
    <Modal
      className={clsx({
        [styles.SecurityDocumentViewer]: !previewError,
      })}
      open={true}
      onCancel={onCancel}
      title={title}
      width="90%"
      destroyOnClose
      footer={<Button onClick={onDownload}>Download</Button>}
    >
      <DocumentViewerContent
        previewBlob={previewBlob}
        previewError={previewError}
        initialPage={initialPage}
      />
    </Modal>
  );
};

export default DocumentViewerModal;
