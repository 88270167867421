import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, List, Modal, Typography } from "antd";
import React, { useState } from "react";
import { AssuranceDocumentResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import { getDocumentsArrangedByGroup } from "../utils";
import styles from "./BulkDownloadModal.module.scss";

interface BulkDownloadModalProps {
  onClose: () => void;
  customerAssuranceDocuments: AssuranceDocumentResponse[];
  onBulkDownloadPublicDocuments: (documents: string[]) => void;
}

const BulkDownloadModal: React.FC<BulkDownloadModalProps> = ({
  onClose,
  customerAssuranceDocuments,
  onBulkDownloadPublicDocuments,
}) => {
  const [selectedDocIds, setSelectedDocIds] = useState<string[]>([]);

  const indeterminate =
    selectedDocIds.length > 0 &&
    selectedDocIds.length !== customerAssuranceDocuments.length;

  const { documentsArrangedByGroup, documentsWithNoGroup } =
    getDocumentsArrangedByGroup(customerAssuranceDocuments);

  const handleSelectDoc = (documentId: string, checked: boolean) => {
    const newSelectedDocs = checked
      ? [...selectedDocIds, documentId]
      : selectedDocIds.filter((id) => id !== documentId);
    setSelectedDocIds(newSelectedDocs);
  };

  const handleSelectAll = (checked: boolean) => {
    const newSelectedDocs = checked
      ? customerAssuranceDocuments.map((doc) => doc.id)
      : [];
    setSelectedDocIds(newSelectedDocs);
  };

  return (
    <Modal
      open={true}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="download"
          type="primary"
          icon={<CloudDownloadOutlined />}
          onClick={() => onBulkDownloadPublicDocuments(selectedDocIds)}
          disabled={selectedDocIds.length === 0}
        >
          Download {selectedDocIds.length}{" "}
          {selectedDocIds.length === 1 ? "document" : "documents"}
        </Button>,
      ]}
      className={styles.BulkDownloadModal}
      centered
    >
      <div className={styles.ModalHeader}>
        <Typography.Title level={3}>Bulk Download</Typography.Title>
        <Typography.Text>
          Documents that are public and available for download can be bulk
          selected below.
        </Typography.Text>
        <div className={styles.SelectAllCheckbox}>
          <Checkbox
            indeterminate={indeterminate}
            checked={
              selectedDocIds.length === customerAssuranceDocuments.length
            }
            onChange={(e) => handleSelectAll(e.target.checked)}
          >
            Select all documents.
          </Checkbox>
        </div>
        {(documentsArrangedByGroup.length > 0 ||
          documentsWithNoGroup.length > 0) && <Divider />}
      </div>

      <div className={styles.ModalBody}>
        {documentsArrangedByGroup.length > 0 &&
          documentsArrangedByGroup.map((group) => (
            <div key={group.id} className={styles.DocumentGroup}>
              <Typography.Title level={5}>
                {group.attributes.title}
              </Typography.Title>
              <List
                dataSource={group.documents}
                renderItem={(document: AssuranceDocumentResponse) => (
                  <List.Item key={document.id}>
                    <Checkbox
                      checked={selectedDocIds.includes(document.id)}
                      onChange={(e) =>
                        handleSelectDoc(document.id, e.target.checked)
                      }
                    >
                      {document.attributes.title}
                    </Checkbox>
                  </List.Item>
                )}
                className={styles.DocumentList}
              />
            </div>
          ))}
        {documentsWithNoGroup.length > 0 && (
          <>
            {documentsArrangedByGroup.length > 0 && (
              <Typography.Title level={5}>Ungrouped Documents</Typography.Title>
            )}
            <List
              dataSource={documentsWithNoGroup}
              renderItem={(document) => (
                <List.Item key={document.id}>
                  <Checkbox
                    checked={selectedDocIds.includes(document.id)}
                    onChange={(e) =>
                      handleSelectDoc(document.id, e.target.checked)
                    }
                  >
                    {document.attributes.title}
                  </Checkbox>
                </List.Item>
              )}
              className={styles.DocumentList}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default BulkDownloadModal;
