import { Column } from "@ant-design/plots";
import { Skeleton, theme } from "antd";
import { useMemo } from "react";
import { useGetConciergeCopilotSearchAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { getAnalyticsMessageComponent } from "../utils";
import { filterData } from "../utils";
import { ConceirgeChartProps } from "./ConciergeContainer";

/**
 * Component that displays metrics for the copilot searches as a column chart
 * @param {CopilotSearcgColumnChartProps} props - Component props
 */
const CopilotSearchColumnChart = ({
  productId,
  activeFilter,
  theme,
  height,
  width,
}: ConceirgeChartProps): JSX.Element => {
  const {
    data: copilotSearchStatsData,
    isLoading: statsLoading,
    isError: statsError,
  } = useGetConciergeCopilotSearchAnalyticsQuery({
    productId,
  });

  const filteredData = useMemo(() => {
    if (!copilotSearchStatsData?.data?.searchStats) return [];

    return filterData(copilotSearchStatsData?.data?.searchStats, activeFilter);
  }, [copilotSearchStatsData, activeFilter]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Copilot Search Statistics",
      subTitle:
        "An unexpected error occurred while fetching Copilot Search data",
    });
  }

  if (
    !copilotSearchStatsData ||
    !copilotSearchStatsData?.data?.searchStats ||
    copilotSearchStatsData?.data?.searchStats.every(
      (stat) => stat.searchCount === 0,
    )
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Copilot Search Data",
      subTitle: "There are no Copilot Search statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "searchCount",
    padding: "auto",
    height,
    width,
    xAxis: {
      label: {
        autoRotate: true,
        style: {
          fontSize: 12,
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      items: [{ name: "Search Count", channel: "y" }],
    },
    axis: {
      y: { title: "Search Count" },
      x: { title: "Month" },
    },
  };

  return <Column {...chartConfig} />;
};

export default CopilotSearchColumnChart;
