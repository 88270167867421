import { Cascader, CascaderProps } from "antd";
import clsx from "clsx";
import { uniqBy } from "lodash";
import { categories } from "../../utils/constants";
import styles from "./CategorySelect.module.css";

interface CategoryOption {
  value: string;
  label: string;
}

type SingleCategorySelectProps = Omit<
  CascaderProps<CategoryOption, "value", false>,
  "value" | "onChange"
> & {
  value?: string;
  onChange?: (value: string | undefined) => void;
};

type MultipleCategorySelectProps = Omit<
  CascaderProps<CategoryOption, "value", true>,
  "value" | "onChange"
> & {
  extraCategories?: string[];
  value?: string[];
  onChange?: (value: string[] | undefined) => void;
};

const allCategoryOptions = (extraCategories: string[]) => {
  const baseOptions: CategoryOption[] = categories.map((category) => ({
    value: category,
    label: category,
  }));

  const flatCategories: CategoryOption[] =
    extraCategories
      .filter((c) => c.trim())
      .map((category) => {
        //sanitizing to resolve https://github.com/SecurityPal/security-pal/issues/3434
        const sanitizedCategory = category.trim().replaceAll("\n", ": ");
        return {
          value: sanitizedCategory,
          label: sanitizedCategory,
        };
      }) ?? [];

  return uniqBy([...baseOptions, ...flatCategories], "value");
};

const showSearch = {
  filter: (inputValue: string, path: CategoryOption[]) => {
    const option = path[0]?.value?.toString().toLowerCase() ?? "";
    return option.indexOf(inputValue.toLowerCase()) >= 0;
  },
};

const SingleCategorySelect = ({
  value,
  onChange,
  ...rest
}: SingleCategorySelectProps): JSX.Element => (
  <Cascader
    {...rest}
    className={clsx(rest.className, styles.CategorySelect)}
    options={allCategoryOptions([])}
    value={value ? [value] : undefined}
    onChange={(v) => onChange?.(v?.[0]?.toString())}
    allowClear={false}
    showSearch={showSearch}
  />
);

const MultipleCategorySelect = ({
  extraCategories,
  onChange,
  value,
  ...rest
}: MultipleCategorySelectProps) => (
  <Cascader
    {...rest}
    className={clsx(rest.className, styles.CategorySelect)}
    options={allCategoryOptions(extraCategories ?? [])}
    allowClear={true}
    showSearch={showSearch}
    multiple
    value={value?.map((v) => [v])}
    onChange={(v) =>
      onChange?.(v.map((item) => item[0]?.toString()).filter(Boolean))
    }
  />
);

export { MultipleCategorySelect, SingleCategorySelect };
