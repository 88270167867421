import { EditOutlined } from "@ant-design/icons";
import { Button, ModalProps, Popover } from "antd";
import { useState } from "react";
import { LogoResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import {
  DeleteLogoPopconfirmProps,
  EditLogoModalProps,
} from "../AssuranceProfileWrapper";
import styles from "./Logo.module.scss";

interface LogoProps {
  logo?: LogoResponse | null;
  CreateModal?: React.FC<ModalProps>;
  EditModal?: React.FC<EditLogoModalProps>;
  DeletePopconfirm?: React.FC<DeleteLogoPopconfirmProps>;
  companyName: string;
}

const AssuranceProfileLogo = ({
  logo,
  CreateModal,
  EditModal,
  DeletePopconfirm,
  companyName,
}: LogoProps): JSX.Element => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      {logo ? (
        <Popover
          trigger="hover"
          content={
            DeletePopconfirm && (
              <div>
                <Button
                  type="text"
                  onClick={() => setEditModalOpen(true)}
                  icon={<EditOutlined />}
                />
                <DeletePopconfirm logo={logo} title="Delete logo?" />
              </div>
            )
          }
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={logo.attributes.homepageUrl}
            aria-label={`Visit ${companyName || ""} homepage.`}
          >
            <img
              alt={companyName ? `${companyName} - home.` : "logo"}
              className={styles.Image}
              src={logo.relationships.logoFile.attributes.publicUrl}
            />
          </a>
        </Popover>
      ) : (
        <Button
          className={styles.UploadButton}
          onClick={() => setCreateModalOpen(true)}
        >
          Upload a logo
        </Button>
      )}
      {CreateModal && (
        <CreateModal
          open={createModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          onOk={() => setCreateModalOpen(false)}
        />
      )}
      {EditModal && logo && (
        <EditModal
          open={editModalOpen}
          onOk={() => setEditModalOpen(false)}
          onCancel={() => setEditModalOpen(false)}
          logo={logo}
        />
      )}
    </>
  );
};

export default AssuranceProfileLogo;
