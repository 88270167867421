import { Form, Modal, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { RcFile } from "antd/lib/upload";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProductSelector } from "../../../components/ActiveProductSelector";
import RequiredFormItem from "../../../components/RequiredFormItem";
import UploadDragger from "../../../components/UploadDragger";
import { useNewEntryGenRequestMutation } from "../../../features/API/entryGen";
import { EntryGenParams } from "../../../features/Copilot/EntryGen/generatedTypes";
import { MULTIPLE_LIBRARY_IDS_QUERY_KEY } from "../../../features/KnowledgeLibrary/utils";
import { useMessageForMutationResultHook } from "../../../utils/hooks";
import { DocumentTypes } from "../documentTypes";

interface NewEntryGenRequestModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  initialProductId?: number;
}

const NewEntryGenRequestModal = ({
  open,
  onCancel,
  onSuccess,
  initialProductId,
}: NewEntryGenRequestModalProps): JSX.Element => {
  const [form] = useForm<EntryGenParams>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (initialProductId) {
      // Set URL params that ProductSelector will read from
      // Because we support only a single product, we clear any existing productIds first
      searchParams.delete(MULTIPLE_LIBRARY_IDS_QUERY_KEY);
      searchParams.set(
        MULTIPLE_LIBRARY_IDS_QUERY_KEY,
        initialProductId.toString(),
      );
      setSearchParams(searchParams);
    }
  }, [initialProductId, searchParams, setSearchParams]);

  const [entryGen, { error, isLoading, status }] =
    useNewEntryGenRequestMutation();

  const successMessage =
    "Knowledge Base Entry Generation has been queued. Please refresh in a minute or two to view generation results.";
  const errorMessage = "Error requesting Knowledge Base Entry Generation";

  useMessageForMutationResultHook(status, error, successMessage, errorMessage);

  const product = Form.useWatch("product", form);
  const [pdfFile, setPdfFile] = useState<RcFile | undefined>(undefined);

  const onSelectFile = async (file?: RcFile) => {
    setPdfFile(file);
  };

  return (
    <Modal
      open={open}
      title="Generate Answer Library Entries from Document"
      confirmLoading={isLoading}
      destroyOnClose
      onCancel={onCancel}
      onOk={async () => {
        const { documentType } = await form.validateFields();
        if (!pdfFile || !documentType) {
          message.error("Must include file and document type");
          return;
        }
        const formData = new FormData();
        formData.append("file", pdfFile);
        formData.append("documentType", documentType);
        formData.append("libraryId", product.id.toString());
        await entryGen(formData);
        form.resetFields();
        onSuccess();
      }}
    >
      <Form layout="vertical" form={form}>
        <RequiredFormItem label="Library" name="product">
          <ProductSelector />
        </RequiredFormItem>
        <RequiredFormItem label="Document Type" name="documentType">
          <Select
            placeholder="Select a document type"
            options={DocumentTypes.map((dT) => ({ label: dT, value: dT }))}
          />
        </RequiredFormItem>
        <UploadDragger
          onSetFile={onSelectFile}
          acceptedFiles=".pdf"
          mainText="PDF File to Answer Library Entries From"
          subtitleText={<li>Click or drag to upload.</li>}
          hideButton={true}
        />
      </Form>
    </Modal>
  );
};

export default NewEntryGenRequestModal;
