import { RcFile } from "antd/lib/upload";
import { Undefined } from "runtypes";
import privateApi from ".";
import { APIEndpoints } from "../../utils/constants";
import { GeneralSocket } from "../../utils/webSockets/socket";
import {
  GetMultipleAnswerData,
  GetMultipleAnswerPaginationParams,
  MultipleAnswerQueryParams,
  PaginationMeta,
  PromoteRequestParams,
  PromoteRequestResponse,
  UpdateAnswerParams,
} from "../Copilot/BulkAnswer/generatedTypes";
import {
  AnswerQueryParams,
  AnswerResponse,
} from "../Copilot/genteratedTypes/api";
import { mutationEndpointBuilder, queryEndpointBuilder } from "./utils";

type MultipleAnswerQueryParamsWithFile = MultipleAnswerQueryParams & {
  file: RcFile;
};

export type GetMultipleAnswerParamsWithQueryId =
  GetMultipleAnswerPaginationParams & {
    requestId: string;
  };

export type UpdateAnswerParamsWithQueryId = UpdateAnswerParams & {
  queryId: string;
};

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    newBulkAnswerRequest: mutationEndpointBuilder<
      undefined,
      string,
      MultipleAnswerQueryParamsWithFile
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.copilot.bulkAnswer.NEW_REQUEST,
      body: (params) => {
        const formData = new FormData();
        formData.append("libraryId", params.libraryId.toString());
        formData.append("sheetName", params.sheetName);
        formData.append("questionColumn", params.questionColumn);
        if (params.answerColumn) {
          formData.append("answerColumn", params.answerColumn);
        }
        formData.append("startingRow", params.startingRow.toString());
        formData.append("prospect", params.prospect);
        if (params.instruction) {
          formData.append("instruction", params.instruction);
        }
        formData.append("file", params.file);
        return formData;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["DashboardQuestionnaire"],
    }),
    getRequest: queryEndpointBuilder<
      PaginationMeta,
      GetMultipleAnswerData,
      GetMultipleAnswerParamsWithQueryId
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ requestId, offset }) =>
        `${APIEndpoints.copilot.bulkAnswer.GET_REQUEST(requestId)}?offset=${offset}`,
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: (_, __, { requestId }) => [
        { type: "BulkAnswer", id: requestId },
      ],
      onCacheEntryAdded: ({ requestId }) => {
        GeneralSocket.socketSend({
          type: "subscribe",
          topic: {
            type: "bulk-answer-request",
            key: requestId,
          },
        });
        GeneralSocket.socketSend({
          type: "subscribe",
          topic: {
            type: "bulk-answer-status",
            key: requestId,
          },
        });
      },
    }),
    promoteRequest: mutationEndpointBuilder<
      undefined,
      PromoteRequestResponse,
      PromoteRequestParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.copilot.bulkAnswer.PROMOTE_REQUEST,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: (_, __, params) => [
        { type: "BulkAnswer", id: params.aiCompletionRequestId },
        "DashboardQuestionnaire",
      ],
    }),
    updateBulkAnswer: mutationEndpointBuilder<
      undefined,
      void,
      UpdateAnswerParamsWithQueryId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: ({ queryId }) =>
        `${APIEndpoints.copilot.bulkAnswer.UPDATE_ANSWER(queryId)}`,
      body: (params) => ({
        answer: params.answer,
      }),
      invalidatesTags: (_, __, params) => [
        { type: "BulkAnswer", id: params.queryId },
      ],
    }),
    getMatchingSources: queryEndpointBuilder<
      void,
      AnswerResponse,
      AnswerQueryParams
    >({
      builder,
      metaRuntype: Undefined,
      url: ({ question, libraryId, instruction, tagIds }) => {
        const queryString = new URLSearchParams({
          question,
          libraryId: libraryId.toString(),
        });
        if (instruction) {
          queryString.set("instruction", instruction);
        }
        if (tagIds) {
          queryString.set("tagIds", tagIds.join(","));
        }

        return `${APIEndpoints.copilot.GET_MATCHING_SOURCES}?${queryString}`;
      },
    }),
  }),
});

export const {
  useNewBulkAnswerRequestMutation,
  useGetRequestQuery,
  usePromoteRequestMutation,
  useUpdateBulkAnswerMutation,
  useGetMatchingSourcesQuery,
  util: bulkAnswersUtil,
} = extendedApi;

export const bulkAnswersApi = extendedApi;
