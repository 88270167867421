import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Table, Tooltip, message } from "antd";
import startCase from "lodash/startCase";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import DocumentViewerModal from "shared_frontend/src/DocumentViewerModal";
import AppHeader from "../../../components/AppHeader";
import {
  useAddToKnowledgeLibraryMutation,
  useGetAnswersForEntryGenRequestQuery,
} from "../../../features/API/entryGen";
import {
  EntryData,
  Excerpt,
} from "../../../features/Copilot/EntryGen/generatedTypes";
import { useDownloadFile } from "../../../utils/helper";
import { downloadEntryGenDocument, previewEntryGenDocument } from "../utils";
import styles from "./EntryGenRequestView.module.css";

type EntryGenRequestViewProps = {
  onBack: () => void;
};
type DocumentPreviewState = {
  isOpen: boolean;
  blob?: Blob;
  error: boolean;
  page: number;
};

const DEFAULT_PAGE_SIZE = 10;

const EntryGenRequestView = (props: EntryGenRequestViewProps) => {
  const { generationId } = useParams();
  const { onBack } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [addToKnowledgeLibrary, { isLoading: isAddingToLibrary }] =
    useAddToKnowledgeLibraryMutation();
  const { getFileContent } = useDownloadFile();

  const [documentPreview, setDocumentPreview] = useState<DocumentPreviewState>({
    isOpen: false,
    error: false,
    page: 1,
  });

  const resetDocumentPreview = () => {
    setDocumentPreview({ isOpen: false, error: false, page: 1 });
  };

  const handleDocumentClick = async (page: number) => {
    if (!generationId) return;

    setDocumentPreview((prev) => ({
      ...prev,
      isOpen: true,
      page,
      error: false,
      blob: undefined,
    }));

    const blob = await previewEntryGenDocument(generationId, getFileContent);
    setDocumentPreview((prev) => ({ ...prev, blob, error: !blob }));
  };

  const handleDocumentDownload = async () => {
    if (!generationId) return;
    await downloadEntryGenDocument(
      generationId,
      getFileContent,
      `${generationId}.pdf`,
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddToLibrary = async () => {
    try {
      const { data } = await addToKnowledgeLibrary(selectedRowKeys as string[]);
      const numSelected = selectedRowKeys.length;
      const numAdded = data?.data?.createdEntries.length || 0;

      if (numAdded === numSelected) {
        message.success("Successfully added entries to knowledge library");
      } else {
        message.warning(
          `Added ${numAdded} out of ${numSelected} entries. Some entries may already exist in the library.`,
        );
      }
      setSelectedRowKeys([]); // Clear selection after success
    } catch (error) {
      message.error("Failed to add entries to knowledge library");
      console.error("Error adding to knowledge library:", error);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: EntryData) => ({
      disabled: record.canonicalQuestionId !== null, // Disable checkbox if answer exists in library
    }),
  };

  const {
    data: answersResult,
    isLoading,
    isFetching,
  } = useGetAnswersForEntryGenRequestQuery(
    generationId
      ? {
          generationId,
          limit: DEFAULT_PAGE_SIZE,
          offset: DEFAULT_PAGE_SIZE * (pageNumber - 1),
        }
      : skipToken,
  );

  const columns = [
    { title: "Question", key: "question", dataIndex: ["question"] },
    {
      title: "Answer",
      key: "answer",
      render: (record: EntryData) => (
        <div>
          {record.answer}
          {record.canonicalQuestionId && (
            <Link
              to={`/knowledge-library/question/${record.canonicalQuestionId}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: 8 }}
            >
              (View Answer Library Entry)
            </Link>
          )}
        </div>
      ),
    },
    {
      title: "Sources",
      key: "sources",
      dataIndex: ["sources"],
      render: (sources: Excerpt[]) => {
        return (
          <ul>
            {sources.map((source) => {
              return (
                <li key={source.text}>
                  {source.text}
                  <Button
                    type="link"
                    onClick={() => handleDocumentClick(source.page)}
                    style={{ padding: 0 }}
                  >
                    (Page {source.page})
                  </Button>
                </li>
              );
            })}
          </ul>
        );
      },
    },
  ];
  const status = answersResult?.data?.status;

  return (
    <>
      <AppHeader
        title={
          <div className={styles.HeaderRow}>
            <Button onClick={onBack}> ← </Button>
            Library Entry Generation Request
          </div>
        }
        extra={
          <>
            <Button
              onClick={handleAddToLibrary}
              disabled={selectedRowKeys.length === 0}
              type="primary"
              style={{ marginRight: 8 }}
              loading={isAddingToLibrary}
            >
              Add to Knowledge Library
            </Button>
          </>
        }
      />

      {status && status !== "completed" && (
        <div>
          <b>Status:</b> {startCase(status || "")}
        </div>
      )}
      <Table<EntryData>
        rowSelection={{
          ...rowSelection,
          renderCell: (
            _: boolean,
            record: EntryData,
            __: number,
            originNode: React.ReactNode,
          ) => {
            if (record.canonicalQuestionId !== null) {
              return (
                <Tooltip title="Entry already added to Knowledge Library">
                  {originNode}
                </Tooltip>
              );
            }
            return originNode;
          },
        }}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={answersResult?.data?.answers || []}
        loading={isLoading || isFetching}
        pagination={{
          current: pageNumber,
          total: answersResult?.meta?.totalCount || 0,
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: (total) => `Total ${total} items`,
        }}
        onChange={(pagination) => {
          if (pagination.current) {
            setPageNumber(pagination.current);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }}
      />
      {documentPreview.isOpen && (
        <DocumentViewerModal
          title={`Document Preview - Page ${documentPreview.page}`}
          onCancel={resetDocumentPreview}
          previewBlob={documentPreview.blob}
          previewError={documentPreview.error}
          initialPage={documentPreview.page}
          onDownload={handleDocumentDownload}
        />
      )}
    </>
  );
};

export default EntryGenRequestView;
