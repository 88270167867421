import { Column } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useGetVendorAssessCompletedByMonthAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { getAnalyticsMessageComponent } from "../utils";
import { filterData } from "../utils";
import { VendorAssessChartProps } from "./VendorAssessAnalyticsContainer";

/**
 * Component that displays metrics for the vendor assess completed as a column chart
 * @param {VendorAssessAssessmentCompletedColumnChartProps} props - Component props
 */
const VendorAssessAssessmentCompletedColumnChart = ({
  companyId,
  activeFilter,
  theme,
  height,
  width,
}: VendorAssessChartProps): JSX.Element => {
  const {
    data: assessmentsCompletedStatsData,
    isLoading: statsLoading,
    isError: statsError,
  } = useGetVendorAssessCompletedByMonthAnalyticsQuery({
    companyId,
  });

  const filteredData = useMemo(() => {
    if (!assessmentsCompletedStatsData?.data?.vendorAssessCompletedStats)
      return [];

    return filterData(
      assessmentsCompletedStatsData?.data?.vendorAssessCompletedStats,
      activeFilter,
    );
  }, [assessmentsCompletedStatsData, activeFilter]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Assessment Completed Statistics",
      subTitle:
        "An unexpected error occurred while fetching Assessment Completed data",
    });
  }

  if (
    !assessmentsCompletedStatsData ||
    !assessmentsCompletedStatsData?.data?.vendorAssessCompletedStats ||
    assessmentsCompletedStatsData?.data?.vendorAssessCompletedStats.every(
      (stat) => stat.assessmentsCompleted === 0,
    )
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Assessment Completed Data",
      subTitle: "There are no Assessment Completed statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "assessmentsCompleted",
    padding: "auto",
    height,
    width,
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      items: [{ name: "Assessment Completed", channel: "y" }],
    },
    axis: {
      y: { title: "Assessments Completed" },
      x: { title: "Month" },
    },
  };

  return <Column {...chartConfig} />;
};

export default VendorAssessAssessmentCompletedColumnChart;
