import { message } from "antd";
import { APIEndpoints } from "../../utils/constants";

export const previewEntryGenDocument = async (
  documentId: string,
  getFileContent: (url: string) => Promise<Blob>,
): Promise<Blob | undefined> => {
  try {
    return await getFileContent(
      `${APIEndpoints.copilot.entryGen.DOWNLOAD_DOCUMENT}?documentId=${documentId}&preview=true`,
    );
  } catch {
    message.error("Failed to load document preview");
    return undefined;
  }
};

export const downloadEntryGenDocument = async (
  documentId: string,
  getFileContent: (url: string) => Promise<Blob>,
  filename?: string,
): Promise<void> => {
  try {
    const blob = await getFileContent(
      `${APIEndpoints.copilot.entryGen.DOWNLOAD_DOCUMENT}?documentId=${documentId}&preview=false`,
    );
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || `document-${documentId}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch {
    message.error("Failed to download document");
  }
};
