import { theme } from "antd";
import type { ThemeConfig } from "antd";
import { darkThemeTokens, lightThemeTokens } from "./tokens";

export const createThemeConfig = (isDarkMode: boolean): ThemeConfig => {
  const tokens = isDarkMode ? darkThemeTokens : lightThemeTokens;

  return {
    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      colorPrimary: tokens.primary.colorPrimary,
      colorBgContainer: tokens.layout.container,
      controlItemBgActive: tokens.menu.selected,
      colorBgElevated: tokens.layout.container,
      colorTextPlaceholder: tokens.text.tertiary,
    },
    components: {
      Layout: {
        bodyBg: tokens.layout.background,
        algorithm: true,
      },
      Button: {
        colorPrimary: tokens.button.primary,
        colorPrimaryHover: tokens.button.hover,
        colorPrimaryActive: tokens.button.pressed,
        colorLink: tokens.button.primary,
        colorLinkHover: tokens.button.hover,
        colorLinkActive: tokens.button.pressed,
      },
      Menu: {
        darkItemBg: tokens.menu.container,
        darkItemHoverBg: tokens.menu.darkItemhover,
        darkItemSelectedBg: tokens.primary.colorPrimary,
        itemSelectedBg: tokens.menu.selected,
        darkSubMenuItemBg: tokens.menu.subMenuItemBg,
      },
      Divider: {
        colorSplit: tokens.divider.default,
      },
      Tabs: {
        itemSelectedColor: tokens.primary.colorPrimary,
      },
      Table: {
        headerBg: tokens.table.header,
        rowSelectedBg: tokens.table.rowSelectedBg,
      },
      Alert: {
        colorErrorBg: tokens.alerts.errorBackground,
        colorErrorBorder: tokens.alerts.errorBorder,
        colorSuccessBg: tokens.alerts.successBackground,
        colorSuccessBorder: tokens.alerts.successBorder,
        colorWarningBg: tokens.alerts.warningBackground,
        colorWarningBorder: tokens.alerts.warningBorder,
        colorInfoBg: tokens.alerts.infoBackground,
        colorInfoBorder: tokens.alerts.infoBorder,
        colorText: tokens.alerts.infoText,
      },
      Result: {
        colorInfo: tokens.primary.colorPrimary,
        colorSuccess: tokens.primary.successColor,
        colorWarning: tokens.primary.warningColor,
        colorError: tokens.primary.errorColor,
      },
      Modal: {
        contentBg: tokens.layout.container,
      },
      Typography: {
        colorLink: tokens.button.primary,
        colorText: tokens.text.primary,
        colorTextSecondary: tokens.text.secondary,
        colorTextTertiary: tokens.text.tertiary,
      },
      Tooltip: {
        colorBgSpotlight: tokens.tooltip.background,
        colorText: tokens.text.primary,
        colorTextLightSolid: tokens.text.primary,
      },
    },
  };
};
