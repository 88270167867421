import { Column } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useGetConciergeTimeSavedAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { TimeSavedStat } from "../../../features/API/AnalyticsDashboard/types";
import { getAnalyticsMessageComponent } from "../utils";
import { filterData } from "../utils";
import { ConceirgeChartProps } from "./ConciergeContainer";

interface TimeSavedColumnChartProps extends ConceirgeChartProps {
  timePerHundredQuestions?: number;
}

/**
 * Component that displays metrics for the time saved as a column chart
 * @param {TimeSavedColumnChartProps} props - Component props
 */
const TimeSavedColumnChart = ({
  productId,
  activeFilter,
  timePerHundredQuestions,
  theme,
  height,
  width,
}: TimeSavedColumnChartProps): JSX.Element => {
  const {
    data: timeSavedStatsData,
    isLoading: statsLoading,
    isError: statsError,
  } = useGetConciergeTimeSavedAnalyticsQuery({ productId });

  const filteredData = useMemo(() => {
    if (!timeSavedStatsData?.data?.timeSavedStats) return [];

    const baseData = filterData(
      timeSavedStatsData?.data?.timeSavedStats,
      activeFilter,
    ) as TimeSavedStat[];

    // If there's a multiplier value, apply it to the time saved hours
    if (timePerHundredQuestions) {
      return baseData.map((item) => ({
        ...item,
        timeSavedInHrs: item.timeSavedInHrs * timePerHundredQuestions,
      }));
    }

    return baseData;
  }, [timeSavedStatsData, activeFilter, timePerHundredQuestions]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Time Saved Statistics",
      subTitle: "An unexpected error occurred while fetching Time Saved data",
    });
  }

  if (
    !timeSavedStatsData ||
    !timeSavedStatsData?.data?.timeSavedStats ||
    timeSavedStatsData?.data?.timeSavedStats.every(
      (stat) => stat.timeSavedInHrs === 0,
    )
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Time Saved Data",
      subTitle: "There are no Time Saved statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "timeSavedInHrs",
    height: height - 30, // Adjusted height to account for the header
    width,
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      items: [{ name: "Time Saved", channel: "y" }],
    },
    axis: {
      y: { title: "Hours Saved" },
      x: { title: "Month" },
    },
  };

  return <Column {...chartConfig} />;
};

export default TimeSavedColumnChart;
