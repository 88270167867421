import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { message, notification } from "antd";
import { Undefined } from "runtypes";
import { X_REQUEST_ID_HEADER_NAME } from "shared_utils/constants";
import TokenHandler from "shared_utils/tokenHandler";
import { v4 as uuidv4 } from "uuid";
import MagicKeyTokenHandler from "../../containers/PrivateDataRoomFlowContainer/token";
import { ValidationResults } from "../../containers/ValidateQuestionnaireContainer/types";
import { clearLoggedInState } from "../../store/clearLoggedInState";
import { APIEndpoints } from "../../utils/constants";
import { JSONAPI } from "../../utils/jsonAPI";
import { PrismDiff } from "../DiffQuestionnaires/types";
import { Permission } from "./permissions";
import { AllRtkQueryTags, Product, ProductId, SPBaseQueryFn } from "./types";
import { mutationEndpointBuilder } from "./utils";

const baseQueryWithMagicLinkAuth: SPBaseQueryFn = async (
  args,
  api,
  extraOptions,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: async (headers) => {
      headers.set(X_REQUEST_ID_HEADER_NAME, uuidv4());
      if (!extraOptions?.unauthed) {
        const accessToken = await MagicKeyTokenHandler.loadToken()?.accessToken;
        headers.set("authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  });
  const r = await baseQuery(args, api, extraOptions);
  if (r.error && r.error.status === "FETCH_ERROR") {
    message.warning("Please check your network connection and try again.");
  }
  if (
    r.meta?.response?.headers &&
    window.ENV_VARIABLE_BACKEND_VERSION_STRING &&
    r.meta.response.headers.get("x-backend-version") !==
      window.ENV_VARIABLE_BACKEND_VERSION_STRING
  ) {
    notification.warning({
      message: "New Update Available",
      description:
        "Please refresh the page to continue. The webapp may not work as expected until you do so",
      duration: 0,
    });
  }
  return r;
};
export const magicLinkApi = createApi({
  reducerPath: "magicLinkApi",
  baseQuery: baseQueryWithMagicLinkAuth,
  tagTypes: AllRtkQueryTags,
  endpoints: () => ({}),
});
export const {
  reducerPath: magicLinkReducerPath,
  reducer: magicLinkReducer,
  middleware: magicLinkMiddleware,
  util: magicLinkApiUtil,
} = magicLinkApi;

interface GetProductsMeta {
  permissions: {
    [key: ProductId]: Permission[];
  };
}
export type ParsedXlsx = {
  sheets: Record<
    string,
    { columns: Record<string, { rows: Record<number, string> }> }
  >;
};

const baseQueryWithTokenRejection: SPBaseQueryFn = async (
  args,
  api,
  extraOptions,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: async (headers) => {
      headers.set(X_REQUEST_ID_HEADER_NAME, uuidv4());
      let accessToken: string | null = null;

      if (!extraOptions?.unauthed) {
        // Logged-in access token takes precedence - use magic key token as secondary option
        try {
          const controller = new AbortController();
          // If the lock isn't acquired within one second, then abort it
          setTimeout(() => controller.abort(), 1000);
          const lockOptions = {
            signal: controller.signal,
          };
          await navigator.locks.request(
            "getAccessTokenLock",
            lockOptions,
            async () => {
              accessToken = await TokenHandler.getAccessToken();
            },
          );
        } catch {
          accessToken = await TokenHandler.getAccessToken();
        }
        headers.set("authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  });
  const r = await baseQuery(args, api, extraOptions);

  if (r.error && r.error.status === 401) {
    // You should not clear logged in state if microsoft login fails. If you do so, you will re-trigger login again and again.
    if (
      typeof args === "string" ||
      args.url !== APIEndpoints.socialAuth.MICROSOFT_LOGIN
    ) {
      api.dispatch(clearLoggedInState());
    }

    message.warning("Your session has expired and you have been logged out.");
  } else if (r.error && r.error.status === "FETCH_ERROR") {
    message.warning("Please check your network connection and try again.");
  }
  if (
    r.meta?.response?.headers &&
    window.ENV_VARIABLE_BACKEND_VERSION_STRING &&
    r.meta.response.headers.get("x-backend-version") !==
      window.ENV_VARIABLE_BACKEND_VERSION_STRING
  ) {
    notification.warning({
      message: "New Update Available",
      description:
        "Please refresh the page to continue. The webapp may not work as expected until you do so",
      duration: 0,
    });
  }
  return r;
};

export const privateApi = createApi({
  reducerPath: "privateApi",
  baseQuery: baseQueryWithTokenRejection,
  tagTypes: AllRtkQueryTags,
  endpoints: (builder) => ({
    getProducts: builder.query<
      JSONAPI<GetProductsMeta | undefined, Product[]>,
      { ignoreServiceEndDate?: boolean }
    >({
      query: ({ ignoreServiceEndDate }) => ({
        url: `${APIEndpoints.answerLibrary.GET_PRODUCTS}?ignoreServiceEndDate=${ignoreServiceEndDate || false}`,
      }),
      providesTags: ["Product"],
    }),
    validateQuestionnaire: builder.mutation<ValidationResults, FormData>({
      query: (formData) => ({
        url: APIEndpoints.validation.VALIDATE_QUESTIONNAIRE,
        method: "POST",
        body: formData,
      }),
    }),
    diffQuestionnaires: builder.mutation<{ prism: PrismDiff[] }, FormData>({
      query: (formData) => ({
        url: APIEndpoints.questionnaireParsing.DIFF_QUESTIONNAIRES,
        method: "POST",
        body: formData,
      }),
    }),
    parseXlsx: mutationEndpointBuilder<undefined, ParsedXlsx, FormData>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.questionnaireParsing.PARSE_XLSX,
      body: (params) => params,
    }),
  }),
});

export default privateApi;
export const {
  reducerPath,
  useGetProductsQuery,
  useValidateQuestionnaireMutation,
  useDiffQuestionnairesMutation,
  useParseXlsxMutation,
  reducer,
  middleware,
  util: apiUtil,
} = privateApi;
