import { Column } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useGetConciergeKLSearchAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { getAnalyticsMessageComponent } from "../utils";
import { filterData } from "../utils";
import { ConceirgeChartProps } from "./ConciergeContainer";

/**
 * Component that displays metrics for the KL searches as a column chart
 * @param {KLSearchColumnChartProps} props - Component props
 */
const KLSearchColumnChart = ({
  productId,
  activeFilter,
  theme,
  height,
  width,
}: ConceirgeChartProps): JSX.Element => {
  const {
    data: klSearchStatsData,
    isLoading: statsLoading,
    isError: statsError,
  } = useGetConciergeKLSearchAnalyticsQuery({
    productId,
  });

  const filteredData = useMemo(() => {
    if (!klSearchStatsData?.data?.searchStats) return [];

    return filterData(klSearchStatsData?.data?.searchStats, activeFilter);
  }, [klSearchStatsData, activeFilter]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load KL Search Statistics",
      subTitle: "An unexpected error occurred while fetching KL Search data",
    });
  }

  if (
    !klSearchStatsData ||
    !klSearchStatsData?.data?.searchStats ||
    klSearchStatsData?.data?.searchStats.every((stat) => stat.searchCount === 0)
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No KL Search Data",
      subTitle: "There are no KL Search statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "searchCount",
    padding: "auto",
    height,
    width,
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      items: [{ name: "Search Count", channel: "y" }],
    },
    axis: {
      y: { title: "Search Count" },
      x: { title: "Month" },
    },
  };

  return <Column {...chartConfig} />;
};

export default KLSearchColumnChart;
