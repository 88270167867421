import { Result, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { BaseChartProps, DateFilterEnum } from "..";
import { ProductId } from "../../../features/API/types";
import styles from "./ContainerBase.module.scss";
import tabStyles from "./TabsStyles.module.scss";

import clsx from "clsx";
import { ThemeType } from "../utils";
import DocumentDownloadChart from "./AssuranceProfileDocumentsDownloadChart";
import InformationRequestChart from "./AssuranceProfileInformationRequestChart";
import PageVisitsChart from "./AssuranceProfilePageVisitsChart";
import PrivateDataRoomChart from "./AssuranceProfilePrivateDataRoomChart";

enum TabKey {
  PageViews = "pageViews",
  InformationRequests = "informationRequests",
  PrivateDataRoomsCreated = "privateDataRoomsCreated",
  TotalSubscribers = "totalSubscribers",
}

interface AssuranceProfileAnalyticsContainerProps {
  productId: ProductId;
  activeFilter: DateFilterEnum;
  selectedProfileIds: string[];
  theme: ThemeType;
  height: number;
  width: number;
}

export interface AssuranceProfileChartProps extends BaseChartProps {
  selectedProfileIds: string[];
}

const AssuranceProfileAnalyticsContainer = ({
  activeFilter,
  selectedProfileIds,
  theme,
  height,
  width,
}: AssuranceProfileAnalyticsContainerProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.PageViews);

  const onChange = (key: TabKey) => {
    setSelectedTab(key);
  };
  const items: TabsProps["items"] = [
    {
      key: TabKey.PageViews,
      label: "Page Visits",
      children: (
        <div className={styles.AnalyticsChart}>
          <PageVisitsChart
            selectedProfileIds={selectedProfileIds}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
    {
      key: TabKey.InformationRequests,
      label: "Information Requests",
      children: (
        <div className={styles.AnalyticsChart}>
          <InformationRequestChart
            selectedProfileIds={selectedProfileIds}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
    {
      key: TabKey.PrivateDataRoomsCreated,
      label: "Private Data Rooms Created",
      children: (
        <div className={styles.PrivateDataRoomsCreatedChart}>
          <PrivateDataRoomChart
            selectedProfileIds={selectedProfileIds}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
    {
      key: TabKey.TotalSubscribers,
      label: "Documents Downloaded",
      children: (
        <div className={styles.DocumentsDownloadedChart}>
          <DocumentDownloadChart
            selectedProfileIds={selectedProfileIds}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
  ];
  return (
    <div className={clsx(styles.AnalyticsContainer, tabStyles.TabsContainer)}>
      {selectedProfileIds.length > 0 ? (
        <Tabs
          defaultActiveKey={TabKey.PageViews}
          activeKey={selectedTab}
          onChange={(key: string) => onChange(key as TabKey)}
          items={items}
        />
      ) : (
        <Result
          status="info"
          title="No Profile Selected"
          subTitle="Please select a profile to view analytics."
        />
      )}
    </div>
  );
};

export default AssuranceProfileAnalyticsContainer;
