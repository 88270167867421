import { AppProps, Typography } from "antd";
import { useRef } from "react";
import SanitizedHTML from "shared_utils/src/SanitizedHTML";
import type { SearchableItem } from ".";
import highlighterForText, { highlighterForHtml } from "../HighlightWords";
import { ensureExhaustive } from "../utils";
import styles from "./AssuranceProfileSearch.module.scss";

interface SearchResultProps extends AppProps {
  item: SearchableItem;
  copyable?: boolean;
  searchQuery: string;
  isDarkModeOn: boolean;
  handleCopy?: (rich: string, plain: string) => void;
}

const { Paragraph } = Typography;

const SearchResult = ({
  item,
  copyable = false,
  searchQuery,
  isDarkModeOn,
  handleCopy,
}: SearchResultProps): JSX.Element => {
  const subtextRef = useRef<HTMLSpanElement>(null);

  const itemType = item.type;
  let title = "";
  let content = "";

  switch (itemType) {
    case "customerAssuranceTileItem":
      title = item.attributes.title;
      content = item.attributes.content;
      break;
    case "customerAssuranceUpdate":
      title = item.attributes.header;
      content = item.attributes.body;
      break;
    default:
      ensureExhaustive(itemType);
  }

  return (
    <>
      <Paragraph
        strong
        copyable={
          copyable
            ? {
                text: content,
                tooltips: ["Copy", "Copied!"],
                onCopy: () =>
                  subtextRef?.current &&
                  handleCopy?.(
                    subtextRef.current.innerHTML,
                    subtextRef.current.innerText ||
                      subtextRef.current.textContent ||
                      "",
                  ),
              }
            : undefined
        }
      >
        {highlighterForText(title, [searchQuery], isDarkModeOn, "normal")}
      </Paragraph>
      <Paragraph className={styles.SubtextParagraph}>
        <SanitizedHTML
          html={highlighterForHtml(content, [searchQuery])}
          subtextRef={subtextRef}
        />
      </Paragraph>
    </>
  );
};

export default SearchResult;
