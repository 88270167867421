import { skipToken } from "@reduxjs/toolkit/query/react";
import { Breakpoint, Card, Grid, Select } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { InlineProductSelector } from "../../components/ActiveProductSelector";
import AppHeader from "../../components/AppHeader";
import LinkButton from "../../components/LinkButton";
import { useGetProfilesQuery } from "../../features/API/CustomerAssurance";
import { useGetMeQuery } from "../../features/API/auth";
import { useActiveProduct, useAppSelector } from "../../utils/hooks";
import { useGetCompanyFromProductId } from "../../utils/hooks"; // Assuming this hook exists
import styles from "./AnalyticsContainer.module.scss";
import AssuranceProfileAnalyticsContainer from "./components/AssuranceProfileAnalyticsContainer";
import ConciergeAnalyticsContainer from "./components/ConciergeContainer";
import VendorAssessAnalyticsContainer from "./components/VendorAssessAnalyticsContainer";
import { ThemeType } from "./utils";

export enum DateFilterEnum {
  LAST_3_MONTHS = "last_3_months",
  LAST_6_MONTHS = "last_6_months",
  LAST_YEAR = "last_year",
}
interface ProfileOption {
  value: string;
  label: string;
}

export interface BaseChartProps {
  height: number;
  width: number;
  activeFilter: DateFilterEnum;
  theme: ThemeType;
}

const AnalyticsContainer = (): JSX.Element => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const activeProduct = useActiveProduct();

  enum SelectDropDownValue {
    CONCIERGE = "concierge",
    ASSURANCE_PROFILE = "assurance_profile",
    VENDOR_ASSESS = "vendor_assess",
  }

  const { data: meData } = useGetMeQuery();
  const bigQueryAnalyticsSearchEnabled =
    meData?.knownFeatureFlags["big-query-analytics-enabled"];
  const isDarkModeOn = useAppSelector((state) => state.layout.isDarkModeOn);
  const isStaff = meData?.user.attributes.isStaff;
  const theme = isDarkModeOn ? "dark" : "light";
  const [selectedValue, setSelectedValue] = useState<SelectDropDownValue>(
    SelectDropDownValue.CONCIERGE,
  );
  const [activeFilter, setActiveFilter] = useState<DateFilterEnum>(
    DateFilterEnum.LAST_YEAR,
  );
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedProfileIds([]);
  }, [activeProduct]);

  const companyId = useGetCompanyFromProductId(activeProduct?.id);
  const dimensions = {
    xxl: { height: 600, width: 1600 },
    xl: { height: 550, width: 1200 },
    lg: { height: 400, width: 900 },
    md: { height: 300, width: 700 },
    sm: { height: 250, width: 500 },
  };

  const getResponsiveDimensions = (
    screens: Partial<Record<Breakpoint, boolean>>,
  ) => {
    if (screens.xxl) return dimensions.xxl;
    if (screens.xl) return dimensions.xl;
    if (screens.lg) return dimensions.lg;
    if (screens.md) return dimensions.md;
    return dimensions.sm;
  };
  const { height, width } = getResponsiveDimensions(screens);

  const { data: profilesData, isLoading: profilesLoading } =
    useGetProfilesQuery(
      selectedValue === SelectDropDownValue.ASSURANCE_PROFILE && companyId
        ? { companyId: companyId }
        : skipToken,
    );

  const handleFilterClick = (filter: DateFilterEnum) => {
    setActiveFilter(filter);
  };

  const handleMenuClick = (value: SelectDropDownValue) => {
    setSelectedValue(value);
    // Reset profile selection when switching
    setSelectedProfileIds([]);
  };

  const statsFilterOptions = [
    {
      value: DateFilterEnum.LAST_3_MONTHS,
      label: "Last 3 months",
    },
    {
      value: DateFilterEnum.LAST_6_MONTHS,
      label: "Last 6 months",
    },
    {
      value: DateFilterEnum.LAST_YEAR,
      label: "Last year",
    },
  ];

  if (!activeProduct) {
    return <></>;
  }

  return (
    <>
      {isStaff && (
        <AppHeader title="Analytics" extra={<InlineProductSelector />} />
      )}
      <Card className={styles.AnalyticsContainer}>
        <div className={styles.AnalyticsHeader}>
          <div className={styles.AnalyticsDropdownContainer}>
            <Select
              defaultValue={SelectDropDownValue.CONCIERGE}
              onSelect={handleMenuClick}
              popupMatchSelectWidth={false}
              options={[
                { value: SelectDropDownValue.CONCIERGE, label: "Concierge" },
                {
                  value: SelectDropDownValue.ASSURANCE_PROFILE,
                  label: "Assurance Profile",
                },
                {
                  value: SelectDropDownValue.VENDOR_ASSESS,
                  label: "Vendor Assess",
                },
              ]}
            />
            {selectedValue === SelectDropDownValue.ASSURANCE_PROFILE && (
              <Select<string[], ProfileOption>
                className={styles.ProfileSelect}
                placeholder="Select a Profile"
                allowClear
                mode="multiple"
                options={profilesData?.data.map((profile) => ({
                  label: profile.attributes.name,
                  value: profile.id!,
                }))}
                onChange={(values) => setSelectedProfileIds(values)}
                showSearch
                filterOption={(inputVal, option) =>
                  option?.label
                    ?.toLowerCase()
                    .includes(inputVal.toLowerCase()) ?? false
                }
                value={selectedProfileIds}
                loading={profilesLoading}
              />
            )}
          </div>
          {screens.lg ? (
            <div className={styles.AnalyticsFilter}>
              {statsFilterOptions.map((filter) => (
                <LinkButton
                  key={filter.value}
                  className={clsx(styles.AnalyticsFilterButton, {
                    [styles.ActiveFilterButton]: activeFilter === filter.value,
                  })}
                  onClick={() =>
                    handleFilterClick(filter.value as DateFilterEnum)
                  }
                >
                  {filter.label}
                </LinkButton>
              ))}
            </div>
          ) : (
            <div className={styles.AnalyticsFilterDropdown}>
              <Select
                value={activeFilter}
                onChange={(value) => handleFilterClick(value as DateFilterEnum)}
                options={statsFilterOptions}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
        <div className={styles.AnalyticsBody}>
          {selectedValue === SelectDropDownValue.CONCIERGE && (
            <ConciergeAnalyticsContainer
              productId={activeProduct.id}
              activeFilter={activeFilter}
              theme={theme}
              bigQueryAnalyticsSearchEnabled={bigQueryAnalyticsSearchEnabled}
              height={height}
              width={width}
            />
          )}
          {selectedValue === SelectDropDownValue.ASSURANCE_PROFILE && (
            <AssuranceProfileAnalyticsContainer
              productId={activeProduct.id}
              activeFilter={activeFilter}
              selectedProfileIds={selectedProfileIds}
              theme={theme}
              height={height}
              width={width}
            />
          )}
          {selectedValue === SelectDropDownValue.VENDOR_ASSESS && (
            <VendorAssessAnalyticsContainer
              companyId={companyId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default AnalyticsContainer;
