import { Undefined } from "runtypes";
import { privateApi } from "..";
import { isAiStatus } from "../../../containers/QuestionnaireDashboardContainer/utils";
import { APIEndpoints } from "../../../utils/constants";
import { GeneralSocket } from "../../../utils/webSockets/socket";
import {
  DashboardQuestionnaireReturnData,
  DashboardQuestionnaireReturnMeta,
} from "../../Concierge/autoGeneratedTypes";
import {
  DashboardFilters,
  DashboardQuestionnaireFormatsReturnData,
  DashboardQuestionnaireFormatsReturnDataRT,
  DashboardQuestionnaireSendersReturnData,
  DashboardQuestionnaireSendersReturnDataRT,
} from "../../Dashboard/types";
import {
  QuestionnaireTask,
  QuestionnaireTaskId,
  QuestionnaireTaskMetaParams,
  QuestionnaireTaskRT,
} from "../../MissionControl/types/tasks";
import {
  NonStaffRequestThread,
  NonStaffRequestThreadRT,
} from "../../MissionControl/types/unions";
import { CompanyId, UserId } from "../types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "../utils";
import { constructQuestionnaireQueryParams } from "./utils";

export interface GetQuestionnairesParams {
  companyId: CompanyId;
  filters: DashboardFilters;
  includeAiCompletedQuestionnaires?: boolean;
  limit?: number;
  offset?: number;
}

type UpdateQuestionnaireTaskAssignedCustomerParams = {
  questionnaireTaskId: QuestionnaireTaskId;
  assignedCustomerUserId?: UserId;
};

const dashboardApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionnaireAnalytics: queryEndpointBuilder<
      DashboardQuestionnaireReturnMeta,
      DashboardQuestionnaireReturnData,
      GetQuestionnairesParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: (params) => {
        return `${
          APIEndpoints.dashboard.GET_QUESTIONNAIRES
        }?${constructQuestionnaireQueryParams(params)}`;
      },
      providesTags: ["DashboardQuestionnaire"],
      extraOptions: {
        checkRunTypes: false,
      },
      onCacheEntryAdded: async (
        requestParams,
        { getCacheEntry, cacheDataLoaded },
      ) => {
        try {
          await cacheDataLoaded;
          const data = getCacheEntry().data?.data;
          if (requestParams.includeAiCompletedQuestionnaires && data) {
            for (const questionnaire of data.questionnaires) {
              if (isAiStatus(questionnaire.status)) {
                GeneralSocket.socketSend({
                  type: "subscribe",
                  topic: {
                    type: "bulk-answer-status",
                    key: questionnaire.id,
                  },
                });
              }
            }
          }
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
      },
    }),

    getQuestionnaireThread: queryEndpointBuilder<
      void,
      NonStaffRequestThread,
      QuestionnaireTaskId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: NonStaffRequestThreadRT,
      url: (id) =>
        `${APIEndpoints.dashboard.GET_QUESTIONNAIRE_THREAD}?questionnaireId=${id}`,
      providesTags: (_, __, id) => [{ type: "DashboardQuestionnaire", id }],
      onCacheEntryAdded: async (questionnaireTaskId) => {
        GeneralSocket.socketSend({
          type: "subscribe",
          topic: { type: "questionnaire-task", key: questionnaireTaskId },
          replaceExisting: true,
        });
      },
    }),
    getQuestionnaireSenders: queryEndpointBuilder<
      void,
      DashboardQuestionnaireSendersReturnData,
      CompanyId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: DashboardQuestionnaireSendersReturnDataRT,
      url: (params) => {
        return `${APIEndpoints.dashboard.GET_QUESTIONNAIRE_SENDERS}?companyId=${params}`;
      },
    }),
    getQuestionnaireFormats: queryEndpointBuilder<
      void,
      DashboardQuestionnaireFormatsReturnData,
      CompanyId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: DashboardQuestionnaireFormatsReturnDataRT,
      url: (companyId) => {
        return `${APIEndpoints.dashboard.GET_QUESTIONNAIRE_FORMATS}?companyId=${companyId}`;
      },
    }),
    updateQuestionnaireTaskMeta: mutationEndpointBuilder<
      void,
      QuestionnaireTask,
      QuestionnaireTaskMetaParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: QuestionnaireTaskRT,
      url: () => APIEndpoints.concierge.UPDATE_QUESTIONNAIRE_TASK_META,
      body: (params) => params,
      invalidatesTags: ["DashboardQuestionnaire"],
    }),
    updateQuestionnaireTaskAssignedCustomer: mutationEndpointBuilder<
      void,
      QuestionnaireTask,
      UpdateQuestionnaireTaskAssignedCustomerParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: QuestionnaireTaskRT,
      url: () =>
        APIEndpoints.concierge.UPDATE_QUESTIONNAIRE_TASK_ASSIGNED_CUSTOMER,
      body: (params) => params,
      invalidatesTags: ["DashboardQuestionnaire"],
    }),
  }),

  overrideExisting: false,
});

export const {
  util: dashboardUtil,
  useGetQuestionnaireAnalyticsQuery,
  useLazyGetQuestionnaireAnalyticsQuery,
  useGetQuestionnaireThreadQuery,
  useGetQuestionnaireSendersQuery,
  useGetQuestionnaireFormatsQuery,
  useUpdateQuestionnaireTaskMetaMutation,
  useUpdateQuestionnaireTaskAssignedCustomerMutation,
} = dashboardApi;

export default dashboardApi;
