type ColorScale = Record<number, string>;
type ThemeMode = "light" | "dark";

interface ButtonTokens {
  primary: string;
  hover: string;
  pressed: string;
}

// Core Color Tokens
export const tokens = {
  neutral: {
    50: "#FFFFFF",
    100: "#F5F5F5",
    200: "#141414",
    300: "#1E1E1E",
    400: "#000000",
  },
  green: {
    50: "#F6FFED",
    100: "#B7EB8F",
    200: "#52C41A",
    300: "#274916",
    400: "#162312",
  },
  yellow: {
    50: "#FFFBE6",
    100: "#FFE58F",
    200: "#FAAD14",
    300: "#594214",
    400: "#2B2111",
  },
  red: {
    50: "#FFF2F0",
    100: "#FFCCC7",
    200: "#FF4D4F",
    300: "#58181C",
    400: "#2A1215",
  },
  blue: {
    50: "#E6F4FF",
    100: "#80ADF3",
    200: "#1A6CE9",
    300: "#015CE6",
    400: "#025ADE",
    500: "#16315A",
    600: "#111A2C",
  },
  grayLight: {
    50: "#F0F0F0",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.15)",
    300: "rgba(0, 0, 0, 0.32)",
    400: "rgba(0, 0, 0, 0.56)",
    500: "rgba(0, 0, 0, 0.88)",
  },
  grayDark: {
    50: "#272727",
    100: "rgba(255, 255, 255, 0.08)",
    200: "rgba(255, 255, 255, 0.25)",
    300: "rgba(255, 255, 255, 0.32)",
    400: "rgba(255, 255, 255, 0.56)",
    500: "rgba(255, 255, 255, 0.88)",
  },
};

// Helper Functions
const makeButtonTokens = (color: ColorScale): ButtonTokens => ({
  primary: color[300],
  hover: color[200],
  pressed: color[400],
});

export const createThemeTokens = (mode: ThemeMode) => {
  const isLight = mode === "light";
  const neutral = tokens.neutral;
  const gray = isLight ? tokens.grayLight : tokens.grayDark;

  return {
    primary: {
      colorPrimary: tokens.blue[300],
      colorPrimaryHover: tokens.blue[200],
      colorPrimaryActive: tokens.blue[400],
      successColor: tokens.green[200],
      errorColor: tokens.red[200],
      warningColor: tokens.yellow[200],
    },
    layout: {
      background: isLight ? neutral[100] : neutral[200],
      container: isLight ? neutral[50] : neutral[300],
      base: neutral[400],
    },
    text: {
      primary: gray[500],
      secondary: gray[400],
      tertiary: gray[300],
    },
    divider: {
      default: gray[100],
      input: gray[200],
      table: gray[50],
    },
    menu: {
      selected: isLight ? tokens.blue[50] : tokens.blue[300],
      darkItemhover: gray[100],
      container: isLight ? neutral[50] : neutral[300],
      subMenuItemBg: gray[50],
    },
    button: makeButtonTokens(tokens.blue),
    table: {
      header: isLight ? tokens.neutral[50] : tokens.neutral[300],
      rowSelectedBg: isLight ? tokens.blue[50] : tokens.blue[600],
    },
    alerts: {
      errorBackground: isLight ? tokens.red[50] : tokens.red[400],
      errorBorder: isLight ? tokens.red[100] : tokens.red[300],
      successBackground: isLight ? tokens.green[50] : tokens.green[400],
      successBorder: isLight ? tokens.green[100] : tokens.green[300],
      warningBackground: isLight ? tokens.yellow[50] : tokens.yellow[400],
      warningBorder: isLight ? tokens.yellow[100] : tokens.yellow[300],
      infoBackground: isLight ? tokens.blue[50] : tokens.blue[600],
      infoBorder: isLight ? tokens.blue[100] : tokens.blue[500],
      infoText: gray[400],
    },
    tooltip: {
      background: gray[50],
    },
  };
};

export const lightThemeTokens = createThemeTokens("light");
export const darkThemeTokens = createThemeTokens("dark");
