import { Pie } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useGetConciergeAnnualRevenueAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { ConciergeAnnualRevenueStats } from "../../../features/API/AnalyticsDashboard/types";
import { getAnalyticsMessageComponent } from "../utils";
import { ConceirgeChartProps } from "./ConciergeContainer";

const AnnualRevenuePieChart = ({
  productId,
  activeFilter,
  theme,
  height,
  width,
}: ConceirgeChartProps): JSX.Element => {
  const {
    data: annualRevenueStats,
    isLoading: isAnnualRevenueStatsLoading,
    error: isAnnnualRevenueStatsError,
  } = useGetConciergeAnnualRevenueAnalyticsQuery({
    productId: productId,
    dateFilter: activeFilter,
  });

  if (isAnnualRevenueStatsLoading) {
    return <Skeleton active />;
  }
  if (isAnnnualRevenueStatsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Annual Revenue Statistics",
      subTitle:
        "An unexpected error occurred while fetching annual revenue data",
    });
  }

  if (
    !annualRevenueStats ||
    !annualRevenueStats.data.length ||
    annualRevenueStats.data.every((stat) => stat.percentage === 0)
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Annual Revenue Data",
      subTitle: "There are no annual revenue statistics available",
    });
  }
  const config = {
    theme,
    data: annualRevenueStats.data,
    angleField: "percentage",
    colorField: "range",
    radius: 0.8,
    height,
    width,
    label: {
      position: "outside",
      text: (data: ConciergeAnnualRevenueStats) =>
        `${data.range}\n${data.percentage}%`,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
        itemMarkerSize: 20,
      },
    },
  };

  return <Pie {...config} />;
};

export default AnnualRevenuePieChart;
