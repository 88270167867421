// AUTO-GENERATED
// THIS FILE IS AUTO-GENERATED. PLEASE DO NOT EDIT IT MANUALLY.
// TO RE-GENERATE THIS FILE, RUN:
//    poetry run python scripts/generate_frontend_permissions.py
import { runtypeFromEnum } from "../../utils/runtypes";

export enum Permission {
  VIEW_ADMIN_PANEL = "view-admin-panel",
  CREATE_COMPANY = "create-company",
  EDIT_COMPANY = "edit-company",
  DEACTIVATE_COMPANY = "deactivate-company",
  REDACT_COMPANY_DATA = "redact-company-data",
  CREATE_PRODUCT = "create-product",
  EDIT_PRODUCT = "edit-product",
  ARCHIVE_PRODUCT = "archive-product",
  VIEW_ALL_QUESTIONNAIRE_TASKS = "see-all-questionnaire-tasks",
  VIEW_QUESTIONNAIRE_TASKS = "view-questionnaire-tasks",
  UPDATE_QUESTIONNAIRE_TASK_META = "update-questionnaire-task-meta",
  UPSERT_QUESTIONNAIRE_TASKS = "upsert-questionnaire-tasks",
  DOWNLOAD_TASK_ATTACHMENTS = "download-task-attachments",
  EXPORT_QUESTIONNAIRES_CSV = "export-questionnaires-csv",
  ADD_QUESTIONS = "add-questions",
  ADD_TAGS = "add-tags",
  BULK_ADD_QUESTIONS = "bulk-add-questions",
  CREATE_TAG = "create-tag",
  DELETE_TAG = "delete-tag",
  UPDATE_TAG = "update-tag",
  VIEW_TAGS = "view-tags",
  DELETE_QUESTIONS = "delete-questions",
  MERGE_QUESTIONS = "merge-questions",
  UPDATE_QUESTIONS = "update-questions",
  OWN_QUESTIONS = "own-questions",
  VIEW_PRODUCTS = "view-products",
  UPDATE_PRODUCTS = "update-products",
  VIEW_QUESTIONS = "view-questions",
  VIEW_COMPANIES = "view-companies",
  EXPORT_QUESTIONS_CSV = "export-questions-csv",
  UPLOAD_QA_IMAGES = "upload-qa-images",
  VIEW_PENDING_QUESTIONS = "view-pending-questions",
  UPDATE_PENDING_QUESTIONS = "update-pending-questions",
  CREATE_PENDING_QUESTIONS = "create-pending-questions",
  UPDATE_PRISM_ENTRY = "update-prism-entry",
  UPDATE_COPILOT_SETTINGS = "update-copilot-settings",
  CREATE_SLACK_SETTINGS = "create-slack-settings",
  DELETE_SLACK_SETTINGS = "delete-slack-settings",
  VIEW_SLACK_SETTINGS = "view-slack-settings",
  ASK_COPILOT = "ask-copilot",
  PARSE_XLSX = "parse-xlsx",
  VIEW_BULK_REQUEST = "view-bulk-request",
  UPSERT_BULK_REQUESTS = "upsert-bulk-requests",
  CUSTOMER_ASSURANCE_VIEW_PROFILES = "customer-assurance-view-profiles",
  CUSTOMER_ASSURANCE_CREATE_PROFILES = "customer-assurance-create-profiles",
  CUSTOMER_ASSURANCE_UPDATE_PROFILES = "customer-assurance-update-profiles",
  CUSTOMER_ASSURANCE_DELETE_NON_PUBLISHED_PROFILES = "customer-assurance-non-published-profiles",
  CUSTOMER_ASSURANCE_DELETE_PROFILES = "customer-assurance-delete-profiles",
  CUSTOMER_ASSURANCE_PUBLISH_PROFILES = "customer-assurance-publish-profiles",
  CUSTOMER_ASSURANCE_VIEW_UPDATES = "customer-assurance-view-updates",
  CUSTOMER_ASSURANCE_CREATE_UPDATES = "customer-assurance-create-updates",
  CUSTOMER_ASSURANCE_UPDATE_UPDATES = "customer-assurance-update-updates",
  CUSTOMER_ASSURANCE_DELETE_UPDATES = "customer-assurance-delete-updates",
  CUSTOMER_ASSURANCE_VIEW_FILES = "customer-assurance-view-files",
  CUSTOMER_ASSURANCE_CREATE_FILES = "customer-assurance-create-files",
  CUSTOMER_ASSURANCE_UPDATE_FILES = "customer-assurance-update-files",
  CUSTOMER_ASSURANCE_DELETE_FILES = "customer-assurance-delete-files",
  CUSTOMER_ASSURANCE_VIEW_TILES = "customer-assurance-view-tiles",
  CUSTOMER_ASSURANCE_CREATE_TILES = "customer-assurance-create-tiles",
  CUSTOMER_ASSURANCE_DELETE_TILES = "customer-assurance-delete-tiles",
  CUSTOMER_ASSURANCE_UPDATE_TILES = "customer-assurance-update-tiles",
  CUSTOMER_ASSURANCE_UPDATE_INFORMATION_REQUEST = "customer-assurance-update-information-request",
  CUSTOMER_ASSURANCE_EXPORT_INFORMATION_REQUESTS = "customer-assurance-export-information-requests",
  CUSTOMER_ASSURANCE_CREATE_MAGIC_LINK = "customer-assurance-create-magic-link",
  CUSTOMER_ASSURANCE_VIEW_PRIVATE_DATA_ROOM = "customer-assurance-view-private-data-room",
  CUSTOMER_ASSURANCE_CREATE_PRIVATE_DATA_ROOM = "customer-assurance-create-private-data-room",
  CUSTOMER_ASSURANCE_LIST_PRIVATE_DATA_ROOMS = "customer-assurance-list-private-data-rooms",
  CUSTOMER_ASSURANCE_REVOKE_PRIVATE_DATA_ROOM_LINK = "customer-assurance-revoke-private-data-room-link",
  CUSTOMER_ASSURANCE_UPDATE_NDA_PROVIDER = "customer-assurance-update-nda-provider",
  CUSTOMER_ASSURANCE_ADD_PUBLIC_KEY = "customer-assurance-add-public-key",
  CUSTOMER_ASSURANCE_GET_ISSUER_ID = "customer-assurance-get-issuer-id",
  CLOSE_ANSWER_REQUESTS = "close-answer-requests",
  CREATE_ANSWER_REQUESTS = "create-answer-requests",
  GET_ANSWER_REQUESTS = "get-answer-requests",
  VIEW_OPEN_ANSWER_REQUEST = "view-open-answer-request",
  CONCIERGE_CREATE_API_TOKENS = "concierge-create-api-tokens",
  CONCIERGE_DELETE_API_TOKENS = "concierge-delete-api-tokens",
  CONCIERGE_REVOKE_API_TOKENS = "concierge-revoke-api-tokens",
  CONCIERGE_VIEW_API_TOKENS = "concierge-view-api-tokens",
  VIEW_API_DOCS = "view-api-docs",
  MISSION_CONTROL_CREATE_MESSAGES = "mission-control-create-messages",
  MISSION_CONTROL_CREATE_TEMPLATES = "mission-control-create-templates",
  MISSION_CONTROL_DELETE_MESSAGES = "mission-control-delete-messages",
  MISSION_CONTROL_DELETE_TEMPLATES = "mission-control-delete-templates",
  MISSION_CONTROL_EDIT_THREADS = "mission-control-edit-threads",
  MISSION_CONTROL_EDIT_TEMPLATES = "mission-control-edit-templates",
  MISSION_CONTROL_SEND_MESSAGES = "mission-control-send-messages",
  MISSION_CONTROL_THREAD_SET_COMPANY = "mission-control-thread-set-company",
  MISSION_CONTROL_VIEW_TEMPLATES = "mission-control-view-templates",
  MISSION_CONTROL_VIEW_THREADS = "mission-control-view-threads",
  MISSION_CONTROL_DOWNLOAD_ATTACHMENTS = "mission-control-download-attachments",
  MISSION_CONTROL_CREATE_LABELS = "mission-control-create-labels",
  MISSION_CONTROL_EDIT_LABELS = "mission-control-edit-labels",
  MISSION_CONTROL_DELETE_LABELS = "mission-control-delete-labels",
  MISSION_CONTROL_ASSIGN_USER = "mission-control-assign-user",
  MISSION_CONTROL_SET_INBOX = "mission-control-set-inbox",
  MISSION_CONTROL_CREATE_COMMENTS = "mission-control-create-comments",
  MISSION_CONTROL_DELETE_COMMENTS = "mission-control-delete-comments",
  MISSION_CONTROL_EDIT_COMMENTS = "mission-control-edit-comments",
  MISSION_CONTROL_VIEW_CONTACTS = "mission-control-view-contacts",
  VIEW_NOTIFICATIONS = "view-notifications",
  UPDATE_NOTIFICATIONS = "update-notifications",
  ADD_QUESTIONNAIRES = "add-questionnaires",
  ADD_QUESTIONNAIRE_RESPONSES = "add-questionnaire-responses",
  SEE_QUESTIONNAIRE_SOURCE_URL = "see-questionnaire-source-url",
  UPDATE_QUESTIONNAIRE_RESPONSES = "update-questionnaire-responses",
  UPDATE_QUESTIONNAIRES = "update-questionnaires",
  VIEW_QUESTIONNAIRES = "view-questionnaires",
  VIEW_QUESTIONNAIRE_RESPONSES = "view-questionnaire-responses",
  VIEW_COMPLETED_QUESTIONNAIRES = "view-completed-questionnaires",
  DELETE_DOCUMENTS = "delete-documents",
  EDIT_DOCUMENTS = "edit-documents",
  UPLOAD_DOCUMENTS = "upload-documents",
  VIEW_DOCUMENTS = "view-documents",
  OWN_DOCUMENTS = "own-documents",
  REVIEW_SUGGESTED_EDITS = "review-suggested-edits",
  SUGGEST_EDITS = "suggest-edits",
  VIEW_SUGGESTED_EDITS = "view-suggested-edits",
  ACCESS_WORKOS_PORTALS = "access-workos-portals",
  CREATE_OR_UPDATE_ACCESS_GROUP = "create-or-update-access-group",
  AUTO_ACTIVATE_NEW_USERS = "auto-activate-new-users",
  CHANGE_USER_ACTIVE = "change-user-active",
  DELETE_ACCESS_GROUP = "delete-access-group",
  MODIFY_ACCESS_GROUP_USERS = "modify-access-group-users",
  ENFORCE_MFA = "enforce-mfa",
  GET_ACCESS_GROUPS = "get-access-groups",
  GET_ACCESS_GROUP_USERS = "get-access-group-users",
  GET_DSYNC_GROUPS = "get-dsync-groups",
  INVITE_USERS = "invite-users",
  MANAGE_OWN_SETTINGS = "manage-own-settings",
  REMOVE_USERS_FROM_COMPANY = "remove-users-from-company",
  SEE_ALL_COMPANY_USERS = "see-all-company-users",
  SEE_ALL_COMPANY_PERMISSIONS = "see-all-company-permissions",
  IMPERSONATE_USERS = "impersonate-users",
  VENDOR_ASSESS_CREATE_AND_UPDATE_ASSESSMENT = "vendor-assess-create-and-update-assessment",
  VENDOR_ASSESS_VIEW_ASSESSMENT = "vendor-assess-view-assessment",
  VENDOR_ASSESS_REQUEST_INFO = "vendor-assess-request-info",
  FIND_SEARCHABLE_QUERY = "find-searchable-query",
  PROVISION_QUESTIONNAIRE_TASKS = "provision-questionnaire-tasks",
  RETURN_COMPLETED_QUESTIONNAIRE_TASKS = "return-completed-questionnaire-tasks",
  VALIDATE_QUESTIONNAIRES = "validate-questionnaires",
  SCRUB_ENTITIES = "scrub-entities",
  DIFF_QUESTIONNAIRES = "diff-questionnaires",
  VIEW_DEMO_COMPANIES = "view-demo-companies",
  ENTRY_GEN = "entry-gen",
  ALTITUDE_REPORTS_VIEW_REPORTS = "altitude-reports-view-reports",
  ALTITUDE_REPORTS_REQUEST_REPORT = "altitude-reports-request-report",
  ALTITUDE_REPORTS_UPLOAD_REPORT = "altitude-reports-upload-report",
  ALTITUDE_REPORTS_UPDATE_REPORT_REQUEST = "altitude-reports-update-report-request",
  ALTITUDE_REPORTS_DELETE_REPORT = "altitude-reports-delete-report",
  SEND_REFERRALS = "send-referrals",
  LINK_SLACK_INSTALLATION = "link-slack-installation",
  GET_SLACKBOT_COMPANY_SETTINGS = "get-slackbot-company-settings",
  UPDATE_SLACKBOT_COMPANY_SETTINGS = "update-slackbot-company-settings",
  EDIT_CUSTOM_TASKS = "edit-custom-tasks",
  VIEW_CUSTOM_TASKS = "view-custom-tasks",
  VIEW_ANALYTICS = "view-analytics",
}
export const PermissionRT = runtypeFromEnum(Permission);
