import {
  Array,
  Boolean,
  Literal,
  Null,
  Number,
  Optional,
  Record as RTRecord,
  Static,
  String,
  Union,
} from "runtypes";
import { ISODateStrRT, ProductId, ProductRT } from "../API/types";
import { AnswerLibraryEntryIdRT } from "../KnowledgeLibrary/types";
import { CanonicalQuestionIdRT } from "../KnowledgeLibrary/types";

const QuestionnaireIdRT = String.withBrand("QuestionnaireId");
export type QuestionnaireId = Static<typeof QuestionnaireIdRT>;
const QuestionnaireResponsesIdRT = String.withBrand("QuestionnaireResponsesId");
export type QuestionnaireResponsesId = Static<
  typeof QuestionnaireResponsesIdRT
>;
const ResponseIdRT = String.withBrand("ResponseId");
export type ResponseId = Static<typeof ResponseIdRT>;
const PRISMEntryIdRT = String.withBrand("PRISMEntryId");
export type PRISMEntryId = Static<typeof PRISMEntryIdRT>;

const QuestionnaireQuestionRT = RTRecord({
  question: String,
  category: Optional(String),
  searchQuery: Optional(String),
});
export type QuestionnaireQuestion = Static<typeof QuestionnaireQuestionRT>;

const CommentTypeRT = Union(
  Literal("Answer"),
  Literal("Attach"),
  Literal("Confirm"),
  Literal("Review"),
  Literal("ReviewAndConfirm"),
  Literal("ReviewAndElaborate"),
  Literal("Elaborate"),
  Literal("Other"),
);
export type CommentType = Static<typeof CommentTypeRT>;

export const CommentRT = RTRecord({
  commentType: CommentTypeRT,
  commentBody: String,
});
export type Comment = Static<typeof CommentRT>;

const QuestionnaireAnswerRT = RTRecord({
  questionIndex: Number,
  answer: String.Or(Null),
  details: String.Or(Null),
  answerLibraryEntryId: Optional(AnswerLibraryEntryIdRT),
  comments: Optional(Array(CommentRT)),
});
export type QuestionnaireAnswer = Static<typeof QuestionnaireAnswerRT>;

const QuestionnaireRT = RTRecord({
  type: Literal("questionnaire"),
  id: QuestionnaireIdRT,
  attributes: RTRecord({
    name: String,
    externalUrl: String.Or(Null),
    questions: Array(QuestionnaireQuestionRT).Or(Null),
    updateTime: ISODateStrRT,
  }),
  relationships: RTRecord({
    product: ProductRT,
  }),
});
export type Questionnaire = Static<typeof QuestionnaireRT>;

export const PRISMEntryRT = RTRecord({
  type: Literal("prismEntry"),
  id: PRISMEntryIdRT,
  attributes: RTRecord({
    answerLibraryEntryId: Number.nullable(),
    canonicalQuestionId: CanonicalQuestionIdRT.nullable(),
    source: String,
    category: String.nullable(),
    question: String,
    clientAnswer: String.nullable(),
    clientDetails: String.nullable(),
    spAnswer: String.nullable(),
    spDetails: String.nullable(),
    spComments: String.nullable(),
    changeAnalysis: String,
    rootCauseAnalysis: String.nullable(),
    status: String,
    createdAt: ISODateStrRT,
    returnedAt: ISODateStrRT.nullable(),
  }),
});
export type PRISMEntry = Static<typeof PRISMEntryRT>;

export const PRISMEntriesRT = RTRecord({
  prismEntries: Array(PRISMEntryRT),
});
export type PRISMEntries = Static<typeof PRISMEntriesRT>;
export const PRISMQuestionnaireRT = RTRecord({
  type: Literal("questionnaire"),
  id: QuestionnaireIdRT,
  attributes: RTRecord({
    name: String,
    sendBackDiffCount: Number,
    externalUrl: String.nullable(),
    questions: Array(QuestionnaireQuestionRT).optional().nullable(),
    updateTime: ISODateStrRT.optional(),
  }),
  relationships: RTRecord({
    product: ProductRT,
    prismEntries: Array(PRISMEntryRT).optional(),
  }),
});
export type PRISMQuestionnaire = Static<typeof PRISMQuestionnaireRT>;

export const CompletedQuestionnaireRT = RTRecord({
  type: Literal("completedQuestionnaire"),
  id: QuestionnaireIdRT,
  attributes: RTRecord({
    name: String,
    externalUrl: String.Or(Null),
    updateTime: ISODateStrRT,
  }),
  relationships: RTRecord({
    product: ProductRT,
  }),
});
export type CompletedQuestionnaire = Static<typeof CompletedQuestionnaireRT>;

export type ResponsesMap = Record<number, QuestionnaireAnswer>;

const ResponseRT = RTRecord({
  type: Literal("response"),
  id: ResponseIdRT,
  attributes: QuestionnaireAnswerRT,
});
export type Response = Static<typeof ResponseRT>;

export const QuestionnaireResponsesRT = RTRecord({
  type: Literal("questionnaireResponses"),
  id: QuestionnaireResponsesIdRT,
  attributes: RTRecord({
    updateTime: ISODateStrRT,
  }),
  relationships: RTRecord({
    questionnaire: QuestionnaireRT,
    responses: Optional(Array(ResponseRT)),
  }),
});
export type QuestionnaireResponses = Static<typeof QuestionnaireResponsesRT>;

export type QuestionnaireSession = {
  currentQuestion: number;
  questionnaireId: QuestionnaireId | "SIG_LITE_EXPERIMENT";
  productId: ProductId;
};

///////////////////////////////////////////////////////////////
// API Shapes
///////////////////////////////////////////////////////////////

// /get-questionnaire-responses
export const GetQuestionnaireResponsesMetaRT = RTRecord({
  total: Number,
  offset: Number,
});
export type GetQuestionnaireResponsesMeta = Static<
  typeof GetQuestionnaireResponsesMetaRT
>;

export type GetQuestionnaireResponsesArgs = {
  productId: ProductId;
  offset?: number;
  limit?: number;
};
