import privateApi from "..";
import { APIEndpoints } from "../../../utils/constants";
import { queryEndpointBuilder } from "../utils";
import {
  AssuranceProfileInformationRequestStats,
  AssuranceProfilePageVisitStats,
  AssuranceProfilePrivateDataRoomStats,
  BaseAssuranceProfileMetricsParams,
  BaseConciergeAnalyticsParams,
  BaseVendorAssessAnalyticsParams,
  ConciergeAnnualRevenueStats,
  ConciergeCompletionPercentageStats,
  ConciergeKLUpdateStats,
  ConciergePendingQuestionStats,
  ConciergeSearchStats,
  DocumentDownloadsAnalyticsStatsResponse,
  GetConciergeAnnualRevenueParams,
  TimeSavedStats,
  VendorAssessStats,
} from "./types";
const analyticsDashboardApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getConciergeCompletionPercentageAnalytics: queryEndpointBuilder<
      undefined,
      ConciergeCompletionPercentageStats,
      BaseConciergeAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_COMPLETION_PERCENTAGE_ANALYTICS}?productId=${productId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getConciergePendingQuestionAnalytics: queryEndpointBuilder<
      void,
      ConciergePendingQuestionStats,
      BaseConciergeAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_PENDING_QUESTION_ANALYTICS}?productId=${productId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getConciergeTimeSavedAnalytics: queryEndpointBuilder<
      void,
      TimeSavedStats,
      BaseConciergeAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_TIME_SAVED_ANALYTICS}?productId=${productId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getConciergeAnnualRevenueAnalytics: queryEndpointBuilder<
      undefined,
      ConciergeAnnualRevenueStats[],
      GetConciergeAnnualRevenueParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId, dateFilter }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_ANNUAL_REVENUE_ANALYTICS}?productId=${productId}&dateFilter=${dateFilter}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getConciergeKLUpdateAnalytics: queryEndpointBuilder<
      void,
      ConciergeKLUpdateStats,
      BaseConciergeAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_KL_UPDATE_ANALYTICS}?productId=${productId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getConciergeCopilotSearchAnalytics: queryEndpointBuilder<
      void,
      ConciergeSearchStats,
      BaseConciergeAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_COPILOT_SEARCH_ANALYTICS}?productId=${productId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getConciergeKLSearchAnalytics: queryEndpointBuilder<
      void,
      ConciergeSearchStats,
      BaseConciergeAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_KL_SEARCH_ANALYTICS}?productId=${productId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getAssuranceProfilePageVisitAnalytics: queryEndpointBuilder<
      undefined,
      AssuranceProfilePageVisitStats[],
      BaseAssuranceProfileMetricsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ profileIds }) => {
        const urlSearchParams = new URLSearchParams();
        profileIds.forEach((profileId) =>
          urlSearchParams.append("profileIds", profileId),
        );
        return `${APIEndpoints.analyticsDashboard.GET_ASSURANCE_PROFILE_PAGE_VISITS_ANALYTICS}?${urlSearchParams.toString()}`;
      },
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getAssuranceProfileInformationRequestsAnalytics: queryEndpointBuilder<
      undefined,
      AssuranceProfileInformationRequestStats[],
      BaseAssuranceProfileMetricsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ profileIds }) => {
        const urlSearchParams = new URLSearchParams();
        profileIds.forEach((profileId) =>
          urlSearchParams.append("profileIds", profileId),
        );
        return `${APIEndpoints.analyticsDashboard.GET_ASSURANCE_PROFILE_INFORMATION_REQUESTS_ANALYTICS}?${urlSearchParams.toString()}`;
      },
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getAssuranceProfilePrivateDataRoomAnalytics: queryEndpointBuilder<
      undefined,
      AssuranceProfilePrivateDataRoomStats[],
      BaseAssuranceProfileMetricsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ profileIds }) => {
        const urlSearchParams = new URLSearchParams();
        profileIds.forEach((profileId) =>
          urlSearchParams.append("profileIds", profileId),
        );
        return `${APIEndpoints.analyticsDashboard.GET_ASSURANCE_PROFILE_PRIVATE_DATA_ROOM_ANALYTICS}?${urlSearchParams.toString()}`;
      },
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getVendorAssessCompletedByMonthAnalytics: queryEndpointBuilder<
      void,
      VendorAssessStats,
      BaseVendorAssessAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ companyId }) =>
        `${APIEndpoints.analyticsDashboard.GET_VENDOR_ASSESS_COMPLETED_BY_MONTH_ANALYTICS}?companyId=${companyId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getAssuranceProfileDocumentsDownloadAnalytics: queryEndpointBuilder<
      undefined,
      DocumentDownloadsAnalyticsStatsResponse,
      BaseAssuranceProfileMetricsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ profileIds }) => {
        const urlSearchParams = new URLSearchParams();
        profileIds.forEach((profileId) =>
          urlSearchParams.append("profileIds", profileId),
        );
        return `${APIEndpoints.analyticsDashboard.GET_ASSURANCE_PROFILE_DOCUMENTS_DOWNLOAD_ANALYTICS}?${urlSearchParams.toString()}`;
      },
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
  }),
});
export const {
  useGetConciergeCompletionPercentageAnalyticsQuery,
  useGetConciergePendingQuestionAnalyticsQuery,
  useGetConciergeTimeSavedAnalyticsQuery,
  useGetConciergeAnnualRevenueAnalyticsQuery,
  useGetConciergeKLUpdateAnalyticsQuery,
  useGetConciergeCopilotSearchAnalyticsQuery,
  useGetConciergeKLSearchAnalyticsQuery,
  useGetAssuranceProfilePageVisitAnalyticsQuery,
  useGetAssuranceProfileInformationRequestsAnalyticsQuery,
  useGetAssuranceProfilePrivateDataRoomAnalyticsQuery,
  useGetVendorAssessCompletedByMonthAnalyticsQuery,
  useGetAssuranceProfileDocumentsDownloadAnalyticsQuery,
} = analyticsDashboardApi;
