import { uncachedFetch } from "shared_utils/src/files/utils";
import { AssuranceDocumentResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";

export const downloadFile = async (url: string): Promise<Blob> => {
  const r = await uncachedFetch(url);
  if (!r.ok) {
    throw new Error(`Failed to download file: ${r.statusText}`);
  }
  return r.blob();
};

export const getBlobFromUrl = async (url: string) => {
  const fileResponse = await uncachedFetch(url);
  if (!fileResponse.ok) {
    throw new Error(
      `Failed to fetch file: ${fileResponse.status} ${fileResponse.statusText}`,
    );
  }
  return fileResponse.blob();
};

const mimeTypeToExtensionMapping: Record<string, string> = {
  "audio/aac": "aac",
  "application/x-abiword": "abw",
  "application/x-freearc": "arc",
  "video/x-msvideo": "avi",
  "application/vnd.amazon.ebook": "azw",
  "application/octet-stream": "bin",
  "image/bmp": "bmp",
  "application/x-bzip": "bz",
  "application/x-bzip2": "bz2",
  "application/x-cdf": "cda",
  "application/x-csh": "csh",
  "text/css": "css",
  "text/csv": "csv",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/vnd.ms-word.document.macroEnabled.12": "docm",
  "application/vnd.ms-fontobject": "eot",
  "application/epub+zip": "epub",
  "image/gif": "gif",
  "text/html": "htm",
  "image/vnd.microsoft.icon": "ico",
  "text/calendar": "ics",
  "image/jpeg": "jpg",
  "text/javascript": "js",
  "application/json": "json",
  "application/ld+json": "jsonld",
  "audio/midi, audio/x-midi": "mid",
  "audio/mpeg": "mp3",
  "video/mp4": "mp4",
  "video/mpeg": "mpeg",
  "application/vnd.apple.installer+xml": "mpkg",
  "application/vnd.oasis.opendocument.presentation": "odp",
  "application/vnd.oasis.opendocument.spreadsheet": "ods",
  "application/vnd.oasis.opendocument.text": "odt",
  "audio/ogg": "oga",
  "video/ogg": "ogv",
  "application/ogg": "ogx",
  "font/otf": "otf",
  "image/png": "png",
  "application/pdf": "pdf",
  "application/x-httpd-php": "php",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12": "pptm",
  "application/vnd.rar": "rar",
  "application/rtf": "rtf",
  "application/x-sh": "sh",
  "image/svg+xml": "svg",
  "application/x-tar": "tar",
  "image/tiff": "tif",
  "video/mp2t": "ts",
  "font/ttf": "ttf",
  "text/plain": "txt",
  "application/vnd.visio": "vsd",
  "audio/wav": "wav",
  "audio/webm": "weba",
  "video/webm": "webm",
  "image/webp": "webp",
  "font/woff": "woff",
  "font/woff2": "woff2",
  "application/xhtml+xml": "xhtml",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-excel.sheet.macroEnabled.12": "xlsm",
  "application/xml": "xml",
  "application/vnd.mozilla.xul+xml": "xul",
  "application/zip": "zip",
  "video/3gpp": "3gp",
  "video/3gpp2": "3g2",
  "application/x-7z-compressed": "7z",
  "application/gzip": "gz",
};

export const getExtensionFromMimeType = (mimeType: string): string => {
  return mimeTypeToExtensionMapping[mimeType] || "Unknown";
};

export const isPDF = (document: AssuranceDocumentResponse): boolean => {
  const fileType = document.relationships.file.attributes.contentType;
  return fileType === "application/pdf";
};
