import { Result } from "antd";
import {
  AssuranceProfileInformationRequestStats,
  AssuranceProfilePageVisitStats,
  AssuranceProfilePrivateDataRoomStats,
  CompletionPercentageStats,
  DateFilterType,
  DocumentDownloadsPerMonth,
  KLUpdateStats,
  PendingQuestionStats,
  SearchStats,
  TimeSavedStat,
  VendorAssessmentCompletedStat,
} from "../../features/API/AnalyticsDashboard/types";

export type Stats =
  | KLUpdateStats
  | TimeSavedStat
  | PendingQuestionStats
  | SearchStats
  | AssuranceProfilePageVisitStats
  | VendorAssessmentCompletedStat
  | AssuranceProfilePrivateDataRoomStats
  | AssuranceProfileInformationRequestStats
  | CompletionPercentageStats
  | DocumentDownloadsPerMonth;

export type ThemeType = "dark" | "light";

export const filterData = (
  data: Stats[],
  activeFilter: DateFilterType,
  isPendingQuestion: boolean = false,
) => {
  switch (activeFilter) {
    case "last_3_months":
      return data.slice(isPendingQuestion ? -3 * 2 : -3);
    case "last_6_months":
      return data.slice(isPendingQuestion ? -6 * 2 : -6);
    case "last_year":
      return data;
    default:
      return data;
  }
};

type MessageType = "error" | "info";

interface MessageProps {
  type: MessageType;
  title: string;
  subTitle: string;
}
export const getAnalyticsMessageComponent = ({
  type,
  title,
  subTitle,
}: MessageProps): JSX.Element => {
  return <Result status={type} title={title} subTitle={subTitle} />;
};
