import { Column } from "@ant-design/plots";
import { Result, Skeleton } from "antd";
import { useGetAssuranceProfileInformationRequestsAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { AssuranceProfileInformationRequestStats } from "../../../features/API/AnalyticsDashboard/types";
import { filterData } from "../utils";
import { AssuranceProfileChartProps } from "./AssuranceProfileAnalyticsContainer";

interface RequestData {
  date: string;
  status: string;
  value: number;
}

const InformationRequestChart = ({
  selectedProfileIds,
  activeFilter,
  theme,
  height,
  width,
}: AssuranceProfileChartProps): JSX.Element => {
  const {
    data: requestStats,
    isLoading: isRequestStatsLoading,
    error: isRequestStatsError,
  } = useGetAssuranceProfileInformationRequestsAnalyticsQuery({
    profileIds: selectedProfileIds,
  });

  if (isRequestStatsLoading) {
    return <Skeleton active />;
  }

  if (isRequestStatsError) {
    return (
      <Result
        status="error"
        title="Failed to Load Information Request Statistics"
        subTitle="An unexpected error occurred while fetching information request data"
      />
    );
  }

  if (
    !requestStats ||
    !requestStats.data ||
    requestStats.data.every((stat) => stat.totalRequests === 0)
  ) {
    return (
      <Result
        status="info"
        title="No Information Request Data"
        subTitle="There are no information request statistics available for the selected profiles"
      />
    );
  }

  // Transform data for stacked column chart
  const transformData = (data: typeof requestStats.data): RequestData[] => {
    return data.flatMap((item) => [
      {
        date: item.date,
        status: "Approved",
        value: item.approvedRequests,
      },
      {
        date: item.date,
        status: "Rejected",
        value: item.rejectedRequests,
      },
      {
        date: item.date,
        status: "Pending",
        value: item.pendingRequests,
      },
    ]);
  };

  const config = {
    theme,
    data: transformData(
      filterData(
        requestStats.data,
        activeFilter,
      ) as AssuranceProfileInformationRequestStats[],
    ),
    xField: "date",
    yField: "value",
    seriesField: "status",
    group: true,
    colorField: "status",
    height,
    width,
    legend: {
      color: {
        position: "right",
        title: false,
        itemMarkerSize: 20,
      },
    },
    axis: {
      y: { title: "Information Requests" },
      x: { title: "Month" },
    },
  };

  return <Column {...config} />;
};

export default InformationRequestChart;
