import { Button } from "antd";
import type { ButtonProps } from "antd";
import clsx from "clsx";
import styles from "./LinkButton.module.css";

type LinkButtonProps = Omit<ButtonProps, "type">;

const LinkButton = (props: LinkButtonProps): JSX.Element => {
  const { className, ...rest } = props;
  return (
    <Button
      {...rest}
      type="link"
      className={clsx(styles.LinkButton, className)}
    />
  );
};

export default LinkButton;
