import {
  GetAssuranceProfileUsageStatsParams,
  GetPrivateDataRoomLinksParams,
} from "../../AssuranceProfile/generatedTypes/profileTypes";
import { DataRoomEventsFilters } from "../../AssuranceProfile/types";

export const constructPrivateDataRoomEventsQueryParams = (
  params: DataRoomEventsFilters,
): string => {
  const searchParams = new URLSearchParams();
  if (params.sortDirection) {
    searchParams.set("sortDirection", params.sortDirection);
  }
  if (params.page) {
    searchParams.set("page", params.page.toString());
  }
  if (params.limit) {
    searchParams.set("limit", params.limit.toString());
  }
  return searchParams.toString();
};

export const constructPrivateDataRoomLinksQueryParams = ({
  profileIds,
  limit,
  page,
  sortField,
  sortOrder,
}: GetPrivateDataRoomLinksParams): string => {
  const searchParams = new URLSearchParams();
  profileIds.forEach((profileId) =>
    searchParams.append("profileIds", profileId),
  );
  if (limit) {
    searchParams.set("limit", limit.toString());
  }
  if (page) {
    searchParams.set("page", page.toString());
  }
  if (sortField) {
    searchParams.set("sortField", sortField);
  }
  if (sortOrder) {
    searchParams.set("sortOrder", sortOrder);
  }

  return searchParams.toString();
};

export const constructAssuranceProfileUsageStatsQueryParams = ({
  endDateInclusive,
  startDateInclusive,
  profileIds,
}: GetAssuranceProfileUsageStatsParams): string => {
  const searchParams = new URLSearchParams();

  profileIds?.forEach((profileId) =>
    searchParams.append("profileIds", profileId),
  );

  if (startDateInclusive) {
    searchParams.append("startDateInclusive", startDateInclusive);
  }

  if (endDateInclusive) {
    searchParams.append("endDateInclusive", endDateInclusive);
  }

  return searchParams.toString();
};
